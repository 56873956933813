import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Paper,
  Avatar,
  InputAdornment,
  Select,
  MenuItem,
  Tooltip,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ArrowDropDown } from "@material-ui/icons";
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";
import BillListEdit from "../../../finance2o/commonComponent/Bill.Item.Table/Bill.List.Edit";
import { mainRoles } from "../../../../helpers/contactHelper";
import * as yup from "yup";
import { MdOutlineForkRight } from "react-icons/md";
import { useDebounce } from "react-use";
import NotifyAlert from "../../../styled/CommonComponents/NotifyAlert";
import "../../../styled/agGrid.css";
import InputWrapper from "../../../styled/CommonComponents/InputWarpper";
import AttachmentsHandler from "../../../styled/CommonComponents/Attachments.Handler";
import ApprovalAndActions from "../../../approval/ApprovalAndActions";
import ApprovalActionDrawer from "../../../approval/ApprovalActionDrawer";
import LockIcon from "@material-ui/icons/Lock";
import { allStatusOptions } from "../../../../helpers/allStatuses";
import PaginatedEntityDropdown from "../../../styled/CommonComponents/PaginatedEntityDropdown";
import { Close } from "@mui/icons-material";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Api from "../../../../helpers/Api";
import { LoadingButton } from "@mui/lab";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getTermsAndConditions } from "../../../OfferingsAndProducts/Api.call";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "white",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  editBody: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "30px 25px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 50px",
    },
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
    paddingRight: "20px",
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  optionsCont: {
    padding: "5px",
  },
  typeSingleOptionSty: {
    padding: "4px 15px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  sectionTop: {
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  basicDataCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  paperCont: {
    width: "100%",
    padding: "15px",
    marginBottom: "50px",
  },
  paperHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    "& h2": {
      fontSize: "18px",
      fontWeight: "600",
    },
  },
  lableValueCont: {
    width: "33.33%",
    padding: "10px",
    "& .inputCont": {
      width: "100%",
    },
  },
  addVendorSty: {
    width: "100%",
    color: "#aeacac",
    borderRadius: "0px",
    cursor: "pointer",
    padding: "9px 10px",
  },
  selectedUserSty: {
    width: "100%",
    padding: "2px 5px",
    cursor: "pointer",
    borderRadius: "0px",
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
      marginLeft: "5px",
    },
    "& .MuiIconButton-root": {
      padding: "3px",
    },
    "& .MuiAvatar-root": {
      width: "30px",
      height: "30px",
    },
    "& h3": {
      fontSize: "13px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "10px",
      fontWeight: "400",
      color: "gray",
    },
  },
  errorSty: {
    fontSize: "12px",
    fontWeight: "400",
    height: "4px",
    width: "100%",
    color: "red",
  },
  starSty: {
    color: "red",
  },
}));

const userOrgPalParam = [
  {
    entity: "User",
    scop: [
      {
        type: "Global",
        role: [],
      },
    ],
  },
  {
    entity: "Organization",
    scop: [
      {
        type: "Global",
        role: [],
      },
    ],
  },
  {
    entity: "User(Pal)",
    scop: [
      {
        type: "Contact",
        role: [...mainRoles],
      },
    ],
  },
  {
    entity: "Group(Pal)",
    scop: [
      {
        type: "Contact",
        role: [...mainRoles],
      },
    ],
  },
  {
    entity: "Organization(Pal)",
    scop: [
      {
        type: "Contact",
        role: [...mainRoles],
      },
    ],
  },
];

const BillOfQuantitiesEdit = ({
  opsOrder,
  setOpsOrder,
  wallet,
  projectProfileId,
  projectId,
  getOpsOrder,
  updateOpsOrderWithUpdate,
  updateOpsOrderWithOutUpdate,
  needToMakeDisable,
  opsOrderStatus,
  setOpsOrderStatus,
  activeStage,
  needToMakeStatusDisable,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { opsOrderId } = useParams();
  const { user } = useSelector((state) => state.auth);

  //helpers
  const [openVendorDrawer, setOpenVendorDrawer] = useState(false);
  const [loadingBool, setLoadingBool] = useState(false);
  const [validationObj, setValidationObj] = useState(null);
  const [billListError, setBillListError] = useState(false);
  const [alertObjValidation, setAlertObjValidation] = useState(null);
  const [needToReloadBillList, setNeedToReloadBillList] = useState(false);
  const [openApprovalFlow, setOpenApprovalFlow] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [actionStatus, setActionStatus] = useState("");

  //values
  const [selectedProject, setSelectedProject] = useState(null);
  const [billListTotals, setBillListTotals] = useState({
    taxExclTotalAmount: 0,
    taxInclTotalAmount: 0,
    totalTaxAmount: 0,
  });
  const [deadline, setDeadline] = useState(new Date());
  const [arrival, setArrival] = useState(new Date());
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [editorContent, setEditorContent] = useState();

  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [selectedTermsAndConditions, setSelectedTermsAndConditions] = useState(
    []
  );
  const [signatures, setSignatures] = useState([]);
  const [selectedSignatures, setSelectedSignatures] = useState([]);
  const [value, setValue] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (opsOrder) {
      setSelectedSignatures(opsOrder.signatures);
      setSelectedTermsAndConditions(opsOrder.termsAndConditions);
    }
  }, [opsOrder]);

  useEffect(() => {
    setOpsOrderStatus(opsOrder?.status || "Draft");
    if (opsOrder?.project?.profile?._id) {
      setSelectedProject({
        value: opsOrder?.project?.profile?._id,
        label: opsOrder?.project?.profile?.parent?.displayName,
        data: opsOrder?.project?.profile,
      });
    } else {
      setSelectedProject(null);
    }
    setBillListTotals({
      taxExclTotalAmount: parseFloat(opsOrder?.untaxedAmountVal || 0).toFixed(
        2
      ),
      taxInclTotalAmount: parseFloat(opsOrder?.totalAmountVal || 0).toFixed(2),
      totalTaxAmount: (
        parseFloat(opsOrder?.totalAmountVal || 0) -
        parseFloat(opsOrder?.untaxedAmountVal || 0)
      ).toFixed(2),
    });
    setDeadline(opsOrder?.orderDeadline || new Date());
    setArrival(opsOrder?.expectedArrival || new Date());
  }, [opsOrder]);

  useEffect(() => {
    setValidationObj(null);
  }, [selectedProject]);

  // have to check
  const updateStatus = async (status) => {
    if (!needToMakeStatusDisable) {
      const basicDataSchema = yup.object().shape({
        project: yup
          .object()
          .shape({ value: yup.string().required("Please select an project") })
          .required("Please select an project")
          .typeError(({ path }) => "Please select an project"),

        expectedArrival: yup
          .date()
          .required("Please enter expected arrival date"),
      });
      const formData = {
        project: selectedProject,
        expectedArrival: arrival,
        orderDeadline: deadline,
      };
      await basicDataSchema
        .validate(formData, { abortEarly: false })
        .then(async (validationRes) => {
          setActionStatus(status);
          setOpenAction(true);
        })
        .catch((err) => {
          console.log(err);
          let errorArr = err?.inner;
          let obj = {};
          errorArr.map((err) => {
            obj[err?.path] = err?.message;
          });
          setValidationObj(obj);
          setAlertObjValidation({
            show: true,
            message: "Please enter valid data in the below forms",
            status: "error",
          });
        });
    }
  };

  useDebounce(
    async () => {
      if (
        opsOrder?._id &&
        selectedProject?.data?.parent?._id != opsOrder?.project?._id
      ) {
        const obj = {
          project: selectedProject?.data?.parent?._id,
          termsAndConditions: editorContent,
          signatures: selectedSignatures.map((item) => item._id),
        };
        setLoadingBool(true);
        await updateOpsOrderWithUpdate(opsOrder?._id, obj);
        setLoadingBool(false);
      }
    },
    2000,
    [selectedProject]
  );

  useDebounce(
    async () => {
      if (
        opsOrder?._id &&
        (billListTotals?.taxInclTotalAmount != opsOrder?.totalAmountVal ||
          opsOrder?.expectedArrival != arrival ||
          opsOrder?.orderDeadline != deadline)
      ) {
        const obj = {
          untaxedAmountVal: parseFloat(billListTotals?.taxExclTotalAmount || 0),
          totalAmountVal: parseFloat(billListTotals?.taxInclTotalAmount || 0),
          expectedArrival: arrival,
          orderDeadline: deadline,
          termsAndConditions: editorContent,
          signatures: selectedSignatures.map((item) => item._id),
        };
        setLoadingBool(true);
        await updateOpsOrderWithOutUpdate(opsOrder?._id, obj);
        setLoadingBool(false);
      }
    },
    2000,
    [billListTotals, deadline, arrival]
  );

  const fetchTermsAndConditions = async () => {
    const response = await getTermsAndConditions({
      libraryId: opsOrder?.project?.wallet?.defaultLibrary,
    });
    if (response?.data) {
      setTermsAndConditions(response?.data);
    } else {
      console.log("No terms and conditions found");
    }
  };

  const fetchSignatures = async () => {
    const { data } = await Api.post("/signature/get/", {
      libraryId: opsOrder?.project?.wallet?.defaultLibrary,
    });

    setSignatures(data);
  };

  useEffect(() => {
    fetchTermsAndConditions();
    fetchSignatures();
  }, [opsOrder?.project?.wallet?.defaultLibrary]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const updatedData = {
        project: selectedProject?.data?.parent?._id,
        termsAndConditions: editorContent,
        signatures: selectedSignatures.map((item) => item?._id),
      };

      await updateOpsOrderWithUpdate(opsOrder?._id, updatedData);
      setAlertObjValidation({
        show: true,
        message: "Data saved successfully",
        status: "success",
      });
      history.goBack();
    } catch (error) {
      console.error("Error saving data:", error);
      setAlertObjValidation({
        show: true,
        message: "Error saving data. Please try again.",
        status: "error",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleTermsChange = (event) => {
    const newTermsIds = event.target.value;
    const newTerms = termsAndConditions.filter((item) =>
      newTermsIds.includes(item._id)
    );
    setSelectedTerms(newTerms);

    const addedTerm = newTerms.find(
      (term) => !selectedTerms.find((t) => t._id === term._id)
    );

    if (addedTerm) {
      const newContent = addedTerm.content;
      const newTitle = addedTerm.title;
      setEditorContent(
        (prev) => prev + `<h3><b>${newTitle}</b></h3>` + newContent + "<br><br>"
      );
    }
  };

  const memoizedSelect = useMemo(
    () => (
      <Select
        fullWidth
        sx={{ padding: "10px" }}
        value={selectedTerms.map((item) => item._id)}
        onChange={handleTermsChange}
        multiple
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {selected.map((value) => {
              const selectedItem = termsAndConditions.find(
                (item) => item._id === value
              );
              return (
                <Typography variant="outlined" key={value}>
                  {selectedItem?.title || "Unknown"}
                </Typography>
              );
            })}
          </Box>
        )}
      >
        {termsAndConditions.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    ),
    [termsAndConditions, selectedTerms]
  );

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={classes.barLeft}>
          <IconButton
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspaceIcon className={classes.backArrow} />
          </IconButton>
          <h3 className={classes.headerTitle}>{opsOrder?.name}</h3>
        </div>
        <div className={classes.barRight}>
          <Button
            id="demo-customized-button"
            variant="outlined"
            size="small"
            color="primary"
            style={{ marginRight: "10px" }}
            onClick={() => {
              setOpenApprovalFlow(true);
            }}
            endIcon={<MdOutlineForkRight />}
          >
            Approval Flow
          </Button>
          <ApprovalAndActions
            openApprovalFlow={openApprovalFlow}
            setOpenApprovalFlow={setOpenApprovalFlow}
            loadingBool={loadingBool}
            setLoadingBool={setLoadingBool}
            parent={opsOrder?._id}
            parentModelName={"OpsOrder"}
            organizationId={opsOrder?.orgId}
            projectId={opsOrder?.project?._id}
            requestId={opsOrder?.approvalRequest?._id}
            templateId={opsOrder?.approvalRequest?.approvalTemplate?._id}
            needToMakeDisable={needToMakeStatusDisable}
            activeStage={activeStage}
            origin={"billOfQuantities"}
          />
          {needToMakeStatusDisable ? (
            <Button
              id="demo-customized-button"
              variant="outlined"
              size="small"
              color="primary"
              disabled
              endIcon={<LockIcon />}
            >
              {opsOrderStatus ? opsOrderStatus : "STATUS"}
            </Button>
          ) : (
            <>
              <MyPopOver
                closeOnClick={true}
                appearContent={
                  <Button
                    id="demo-customized-button"
                    variant="outlined"
                    size="small"
                    color="primary"
                    endIcon={<ArrowDropDown />}
                  >
                    {opsOrderStatus ? opsOrderStatus : "STATUS"}
                  </Button>
                }
                showContent={
                  <div className={classes.optionsCont}>
                    {allStatusOptions[opsOrder?.type][opsOrder?.status] &&
                      allStatusOptions[opsOrder?.type][opsOrder?.status].map(
                        (statusOption, i) => (
                          <div
                            key={i}
                            className={classes.typeSingleOptionSty}
                            onClick={() => {
                              updateStatus(statusOption);
                            }}
                          >
                            {statusOption}
                          </div>
                        )
                      )}
                  </div>
                }
              />
              <ApprovalActionDrawer
                openAction={openAction}
                setOpenAction={setOpenAction}
                loadingBool={loadingBool}
                setLoadingBool={setLoadingBool}
                actionType={"takeAction"}
                origin={"billOfQuantities"}
                parent={opsOrder?._id}
                parentModelName={"OpsOrder"}
                statusField={"status"}
                statusValue={actionStatus}
                organizationId={opsOrder?.orgId}
                projectId={opsOrder?.project?._id}
                requestId={opsOrder?.approvalRequest?._id}
                templateId={opsOrder?.approvalRequest?.approvalTemplate?._id}
                activeStage={activeStage}
                actionCallBack={(type, curStatues) => {
                  getOpsOrder();
                }}
              />
            </>
          )}
        </div>
      </div>
      <div style={{ width: "100%", height: "4px" }}>
        {loadingBool && <LinearProgress />}
      </div>
      <div className={classes.editBody}>
        <Paper className={classes.paperCont} elevation={2}>
          <div className={classes.paperHeader}>
            <h2>Basic Data</h2>
            <h2></h2>
          </div>
          <div className={classes.basicDataCont}>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={"Owner"}
                inputComp={
                  <div className="inputCont">
                    <div className={classes.selectedUserSty}>
                      <Avatar
                        src={
                          opsOrder?.projectParent?.displayPicture?.thumbUrl
                            ? opsOrder?.projectParent?.displayPicture?.thumbUrl
                            : opsOrder?.projectParent?.displayPicture?.url
                        }
                        alt={selectedProject?.data?.parent?.displayName}
                      />
                      <div style={{ marginLeft: "5px" }}>
                        <h3>{opsOrder?.projectParent?.displayName}</h3>
                        <p>{opsOrder?.projectParent?.username}</p>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={
                  <>
                    Project<span className={classes.starSty}>*</span>
                  </>
                }
                inputComp={
                  <div className="inputCont">
                    <PaginatedEntityDropdown
                      value={selectedProject}
                      onChange={(value) => {
                        setSelectedProject(value);
                      }}
                      isMulti={false}
                      entity={"Project"}
                      curEntityId={projectId}
                      givenProfileIdArr={[user?.profile]}
                      palCreate={false}
                      disabled={needToMakeDisable}
                      noFilter={true}
                      givenFilterOption={[
                        {
                          option: "Network",
                          types: ["Project"],
                        },
                      ]}
                    />
                  </div>
                }
                validation={
                  validationObj?.project
                    ? validationObj?.project
                    : validationObj?.["project.value"]
                      ? validationObj?.["project.value"]
                      : ""
                }
              />
            </div>
          </div>
        </Paper>

        <Paper className={classes.paperCont} elevation={2}>
          <div className={classes.paperHeader}>
            <h2>Edit Items</h2>
            <div></div>
          </div>
          {opsOrder?.project?._id && opsOrder?.billList && (
            <BillListEdit
              billListId={opsOrder?.billList}
              wallet={wallet}
              projectProfileId={projectProfileId}
              needToMakeDisable={needToMakeDisable}
              setBillListError={setBillListError}
              setBillListTotals={setBillListTotals}
              billListTotals={billListTotals}
              setNeedToReloadBillList={setNeedToReloadBillList}
            />
          )}
        </Paper>

        {opsOrder?._id && (
          <Paper className={classes.paperCont} elevation={2}>
            <AttachmentsHandler
              parentData={opsOrder}
              setParentData={setOpsOrder}
              needToMakeDisable={needToMakeDisable}
              parentModel={"OpsOrder"}
              setLoadingBool={setLoadingBool}
            />
          </Paper>
        )}

        <Paper className={classes.paperCont} elevation={2}>
          <div className={classes.paperHeader}>
            <h2>Signatures.</h2>
          </div>
          <InputWrapper
            inputComp={
              <div
                className="inputCont"
                style={{
                  width: "100%",
                }}
              >
                <Select
                  fullWidth
                  sx={{ padding: "10px" }}
                  value={selectedSignatures.map((item) => item?._id)}
                  onChange={(event) => {
                    const newTerms = signatures.filter((item) =>
                      event.target.value.includes(item._id)
                    );
                    setSelectedSignatures(() => newTerms);
                  }}
                  multiple
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "no-wrap",
                        gap: 1,
                      }}
                    >
                      {selected.map((value, index) => {
                        const selectedItem = signatures.find(
                          (item) => item._id === value
                        );
                        return (
                          <Typography variant="outlined" key={value}>
                            {selectedItem?.title || "Unknown"}
                            {index === selected.length - 1 ? ".  " : ", "}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                >
                  {signatures.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            }
          />
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {selectedSignatures.map((item, index) => (
              <>
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    padding: "20px",
                    alignItems: "start",
                  }}
                >
                  <Typography variant="h6" color={"primary"}>
                    {index + 1}.
                  </Typography>
                  <Box>
                    <Typography variant="h6" color={"primary"}>
                      {item?.title}. {item?.language}{" "}
                    </Typography>
                    <Box
                      sx={{
                        marginTop: "1rem",
                      }}
                    >
                      <Table
                        size="small"
                        aria-label="ultra-compact signature fields table"
                        sx={{
                          "& .MuiTableCell-root": {
                            padding: "4px 8px",
                            fontSize: "0.75rem",
                            lineHeight: 1.2,
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                border: "1px solid black",
                                backgroundColor: "#f5f5f5",
                              }}
                            >
                              Designation
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                border: "1px solid black",
                                backgroundColor: "#f5f5f5",
                              }}
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item?.fields
                            ?.slice(0, 3)
                            .map((field, fieldIndex) => (
                              <TableRow
                                key={fieldIndex}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    borderBottom: 0,
                                  },
                                  "&:nth-of-type(even)": {
                                    backgroundColor: "#fafafa",
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    borderLeft: "1px solid black",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {field.name}
                                </TableCell>
                                <TableCell
                                  sx={{ borderRight: "1px solid black" }}
                                >
                                  {field.action}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                  <Tooltip title="Remove">
                    <IconButton
                      size="small"
                      onClick={() =>
                        setSelectedSignatures((prev) =>
                          prev.filter((tAndC) => item._id !== tAndC._id)
                        )
                      }
                      sx={{ color: "primary.contrastText" }}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider />
              </>
            ))}
          </div>
        </Paper>

        <Paper className={classes.paperCont} elevation={2}>
          <div className={classes.paperHeader}>
            <h2>Terms and conditions</h2>
          </div>
          <InputWrapper
            inputComp={
              <div
                className="inputCont"
                style={{
                  width: "100%",
                }}
              >
                {memoizedSelect}
              </div>
            }
          />
          <div
            style={{
              marginTop: "20px",
              maxHeight: "400px",
            }}
          >
            <ReactQuill
              theme="snow"
              style={{
                maxHeight: "400px",
                overflowY: "auto",
              }}
              value={editorContent}
              onChange={setEditorContent}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline"],
                  ["clean"],
                ],
              }}
            />
          </div>
        </Paper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <LoadingButton
              variant="contained"
              onClick={handleSave}
              loading={isSaving}
              disabled={needToMakeDisable || isSaving}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>

        <NotifyAlert
          alertObj={alertObjValidation}
          setAlertObj={setAlertObjValidation}
        />
      </div>
    </div>
  );
};

export default BillOfQuantitiesEdit;
