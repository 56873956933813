import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Paper,
  Avatar,
  InputAdornment,
  Card,
  CardContent,
  LinearProgress,
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Chip,
} from "@material-ui/core";
import { Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  ArrowDropDown,
  Description,
  AttachMoney,
  Visibility,
  Delete,
  Close,
} from "@material-ui/icons";
import LockIcon from "@material-ui/icons/Lock";
import { FaPercent } from "react-icons/fa6";
import { MdOutlineForkRight } from "react-icons/md";
import moment from "moment";
import * as yup from "yup";
import { useDebounce, useSetState } from "react-use";
import { getTermsAndConditions } from "../../../OfferingsAndProducts/Api.call";

import BudgetUpdate from "../../../budgetCostAnalysis/BudgetUpdate";
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";
import BillListEdit from "../../../finance2o/commonComponent/Bill.Item.Table/Bill.List.Edit";
import TextFieldNumberFormated from "../../../styled/CommonComponents/TextFieldNumberFormated";
import NotifyAlert from "../../../styled/CommonComponents/NotifyAlert";
import config from "../../../../config/index";
import { setAuthCookieData } from "../../../../helpers/cookie.helper";
import InputWrapper from "../../../styled/CommonComponents/InputWarpper";
import ApprovalAndActions from "../../../approval/ApprovalAndActions";
import AttachmentsHandler from "../../../styled/CommonComponents/Attachments.Handler";
import ExportPDF from "../../../styled/generic/ExportPDF";
import ApprovalActionDrawer from "../../../approval/ApprovalActionDrawer";
import { allStatusOptions } from "../../../../helpers/allStatuses";
import PaginatedEntityDropdown from "../../../styled/CommonComponents/PaginatedEntityDropdown";
import BlocksAndTasksHandler from "../../../finance2o/salesAndInvoices/BlocksAndTasksHandler";
import LinkedDocsDrawer from "./LinkedDocsDrawer";
import Api from "../../../../helpers/Api";
import { Divider, Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "white",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  editBody: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "30px 25px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 50px",
    },
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
    paddingRight: "20px",
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  optionsCont: {
    padding: "5px",
  },
  typeSingleOptionSty: {
    padding: "4px 15px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  sectionTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  basicDataCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  paperCont: {
    width: "100%",
    padding: "15px",
    marginBottom: "50px",
  },
  paperHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    "& h2": {
      fontSize: "18px",
      fontWeight: "600",
    },
  },
  lableValueCont: {
    width: "33.33%",
    padding: "10px",
    "& .inputCont": {
      width: "100%",
    },
  },
  addVendorSty: {
    width: "100%",
    color: "#aeacac",
    borderRadius: "0px",
    cursor: "pointer",
    padding: "9px 10px",
  },
  selectedUserSty: {
    width: "100%",
    padding: "2px 5px",
    cursor: "pointer",
    borderRadius: "0px",
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
      marginLeft: "5px",
    },
    "& .MuiIconButton-root": {
      padding: "3px",
    },
    "& .MuiAvatar-root": {
      width: "30px",
      height: "30px",
    },
    "& h3": {
      fontSize: "13px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "10px",
      fontWeight: "400",
      color: "gray",
    },
  },
  errorSty: {
    fontSize: "12px",
    fontWeight: "400",
    height: "4px",
    width: "100%",
    color: "red",
  },
  starSty: {
    color: "red",
  },
  container: {
    width: "100%",
    "& tr": {
      height: "45px",
    },
    "&.MuiTableCell-root": {
      outline: "1px solid #ececec",
      border: "none",
    },
    "& table": {
      outline: "1px solid #d2d2d2",
      border: "1px solid #ececec",
      borderCollapse: "collapse",
    },
    "& th": {
      outline: "1px solid #d2d2d2",
      border: "none",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
    },
    "& td": {
      outline: "1px solid #d2d2d2",
      border: "none",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "0px 10px",
    },
  },
  selectedUserOrgSty: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
      marginLeft: "5px",
    },
    "& .MuiIconButton-root": {
      padding: "3px",
    },
    "& .MuiAvatar-root": {
      width: "30px",
      height: "30px",
    },
    "& h3": {
      fontSize: "13px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "10px",
      fontWeight: "400",
      color: "gray",
      textAlign: "left",
    },
  },
  linkedDocsSection: {
    marginBottom: theme.spacing(4),
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },

  card: {
    height: "90%",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    transition: "all 0.3s",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: theme.shadows[4],
    },
  },
  cardContent: {
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    "& > *:first-child": {
      marginRight: theme.spacing(1),
    },
  },
  boldText: {
    fontWeight: "bold",
  },
}));

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const WorkorderEdit = ({
  opsOrder,
  setOpsOrder,
  wallet,
  projectProfileId,
  projectId,
  certificates,
  updateOpsOrderWithUpdate,
  updateOpsOrderWithOutUpdate,
  needToMakeDisable,
  opsOrderStatus,
  setOpsOrderStatus,
  getOpsOrder,
  activeStage,
  needToMakeStatusDisable,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isDev = config.mode == "dev";

  const { opsOrderId } = useParams();
  const { user } = useSelector((state) => state.auth);

  //helpers
  const [openVendorDrawer, setOpenVendorDrawer] = useState(false);
  const [loadingBool, setLoadingBool] = useState(false);
  const [validationObj, setValidationObj] = useState(null);
  const [billListError, setBillListError] = useState(false);
  const [alertObjValidation, setAlertObjValidation] = useState(null);
  const [needToReloadBillList, setNeedToReloadBillList] = useState(false);
  const [openApprovalFlow, setOpenApprovalFlow] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [actionStatus, setActionStatus] = useState("");
  const [openLinkedDocsDrawer, setOpenLinkedDocsDrawer] = useState(false);
  const [linkedDocsDetails, setLinkedDocsDetails] = useState([]);
  const [linkedDocs, setLinkedDocs] = useState(opsOrder?.linkedDocs || []);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [editorContent, setEditorContent] = useState();

  //values
  const [selectedBudgetId, setSelectedBudgetId] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [retantionPercentage, setRetantionPercentage] = useState(0);
  const [selectedProject, setSelectedProject] = useState({});
  const [budgetUpdateStatus, setBudgetUpdateStatus] = useState(null);
  const [totalCFAmount, setTotalCFAmount] = useState(0);
  const [billListTotals, setBillListTotals] = useState({
    taxExclTotalAmount: 0,
    taxInclTotalAmount: 0,
    totalTaxAmount: 0,
  });
  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [selectedTermsAndConditions, setSelectedTermsAndConditions] = useState(
    []
  );
  const [signatures, setSignatures] = useState([]);
  const [selectedSignatures, setSelectedSignatures] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (opsOrder) {
      setSelectedSignatures(opsOrder.signatures);
      setSelectedTermsAndConditions(opsOrder.termsAndConditions);
    }
  }, [opsOrder]);

  const fetchLinkedDocsDetails = async (docIds) => {
    try {
      const response = await Api.post("/procurement/ops/get-linked-docs", {
        linkedDocIds: docIds,
      });
      console.log("The linked docs response:", response);
      setLinkedDocsDetails(response.linkedDocs);
    } catch (error) {
      console.error("Error fetching linked documents details:", error);
    }
  };

  useEffect(() => {
    if (opsOrder.linkedDocs && opsOrder.linkedDocs.length > 0) {
      fetchLinkedDocsDetails(opsOrder.linkedDocs);
    } else {
      setLinkedDocsDetails([]);
    }
  }, [opsOrder.linkedDocs]);

  const handleLinkedDocsUpdate = (newLinkedDocs) => {
    setOpsOrder((prevOpsOrder) => ({
      ...prevOpsOrder,
      linkedDocs: newLinkedDocs,
    }));
    fetchLinkedDocsDetails(newLinkedDocs);
  };

  useEffect(() => {
    let locAmount = 0;
    certificates.map((cf) => {
      locAmount = locAmount + parseFloat(cf?.netPayment || 0);
    });
    setTotalCFAmount(locAmount);
  }, [certificates]);

  useEffect(() => {
    if (opsOrderStatus === "Draft") {
      setBudgetUpdateStatus("Draft");
    } else if (
      opsOrderStatus === "Submitted" ||
      opsOrderStatus === "Under Review" ||
      opsOrderStatus === "Evaluated"
    ) {
      setBudgetUpdateStatus("Submitted");
    } else if (opsOrderStatus !== "Rejected") {
      setBudgetUpdateStatus("Approved");
    } else {
      setBudgetUpdateStatus("Draft");
    }
  }, [opsOrderStatus]);

  useEffect(() => {
    setSelectedBudgetId(opsOrder?.selectedBudgetIds || []);
    setOpsOrderStatus(opsOrder?.status || "Draft");
    if (opsOrder?.project?.profile?._id) {
      setSelectedProject({
        value: opsOrder?.project?.profile?._id,
        label: opsOrder?.project?.profile?.parent?.displayName,
        data: opsOrder?.project?.profile,
      });
    } else {
      setSelectedProject(null);
    }
    if (opsOrder?.vendor?._id) {
      setVendor({
        value: opsOrder?.vendor?._id,
        label: opsOrder?.vendor?.parent?.displayName,
        data: opsOrder?.vendor,
      });
    } else {
      setVendor(null);
    }
    setRetantionPercentage(opsOrder?.retentionPercentage || 0);
    setAdvanceAmount(parseFloat(opsOrder?.advanceAmount || 0));
    setBillListTotals({
      taxExclTotalAmount: parseFloat(opsOrder?.untaxedAmountVal || 0).toFixed(
        2
      ),
      taxInclTotalAmount: parseFloat(opsOrder?.totalAmountVal || 0).toFixed(2),
      totalTaxAmount: (
        parseFloat(opsOrder?.totalAmountVal || 0) -
        parseFloat(opsOrder?.untaxedAmountVal || 0)
      ).toFixed(2),
    });
  }, [opsOrder]);

  const addNewSelectedItems = async (items) => {
    const updatedOpsOrder = {
      ...opsOrder,
      selectedBudgetIds: items,
    };
    setOpsOrder(updatedOpsOrder);
    await updateOpsOrderWithOutUpdate(opsOrder?._id, {
      selectedBudgetIds: items,
    });
  };

  useEffect(() => {
    setValidationObj(null);
  }, [vendor, selectedProject, retantionPercentage, advanceAmount]);

  const updateStatus = async (status) => {
    if (!needToMakeStatusDisable) {
      const basicDataSchema = yup.object().shape({
        vendor: yup
          .object()
          .shape({ value: yup.string().required("Please select a vendor") })
          .required("Please select a vendor")
          .typeError("Please select a vendor"),

        project: yup
          .object()
          .shape({ value: yup.string().required("Please select a project") })
          .required("Please select a project")
          .typeError("Please select a project"),

        ["retantion percentage"]: yup
          .number()
          .min(0)
          .max(100)
          .required("Retention percentage is required"),

        ["advance amount"]: yup
          .number()
          .min(0)
          .max(parseFloat(billListTotals?.taxInclTotalAmount || 0))
          .required("Advance amount is required"),
      });
      const formData = {
        vendor: vendor,
        project: selectedProject,
        ["retantion percentage"]: retantionPercentage,
        ["advance amount"]: advanceAmount,
      };
      await basicDataSchema
        .validate(formData, { abortEarly: false })
        .then(async (validationRes) => {
          setActionStatus(status);
          setOpenAction(true);
        })
        .catch((err) => {
          console.log(err);
          let errorArr = err?.inner;
          let obj = {};
          errorArr.map((err) => {
            obj[err?.path] = err?.message;
          });
          setValidationObj(obj);
          setAlertObjValidation({
            show: true,
            message: "Please enter valid data in the below forms",
            status: "error",
          });
        });
    }
  };

  const handleUpdate = async () => {
    if (
      opsOrder?._id &&
      (vendor?.value != opsOrder?.vendor?._id ||
        selectedProject?.data?.parent?._id != opsOrder?.project?._id)
    ) {
      const obj = {
        vendor: vendor?.value,
        project: selectedProject?.data?.parent?._id,
        termsAndConditions: (selectedTermsAndConditions && selectedTermsAndConditions.length > 0) ? selectedTermsAndConditions.map((item) => item?._id) : [],
        signatures: (selectedSignatures && selectedSignatures.length > 0) ? selectedSignatures.map((item) => item?._id) : [],
      };
      setLoadingBool(true);
      await updateOpsOrderWithUpdate(opsOrder?._id, obj);
      setLoadingBool(false);
    }
  };

  useDebounce(handleUpdate, 2000, [
    vendor,
    selectedProject,
    selectedSignatures,
    selectedTermsAndConditions,
  ]);

  useDebounce(
    async () => {
      if (
        opsOrder?._id &&
        (advanceAmount != opsOrder?.advanceAmount ||
          billListTotals?.taxInclTotalAmount != opsOrder?.totalAmountVal ||
          retantionPercentage != opsOrder?.retentionPercentage)
      ) {
        const obj = {
          advanceAmount: parseFloat(advanceAmount || 0),
          untaxedAmountVal: parseFloat(billListTotals?.taxExclTotalAmount || 0),
          totalAmountVal: parseFloat(billListTotals?.taxInclTotalAmount || 0),
          retentionPercentage: parseFloat(retantionPercentage || 0),
          termsAndConditions: editorContent,
          signatures: selectedSignatures.map((item) => item._id),
        };
        setLoadingBool(true);
        await updateOpsOrderWithOutUpdate(opsOrder?._id, obj);
        setLoadingBool(false);
      }
    },
    2000,
    [
      advanceAmount,
      retantionPercentage,
      billListTotals,
      selectedTermsAndConditions,
    ]
  );

  const fetchTermsAndConditions = async () => {
    const response = await getTermsAndConditions({
      libraryId: opsOrder?.project?.wallet?.defaultLibrary,
    });
    if (response?.data) {
      setTermsAndConditions(response?.data);
    } else {
      console.log("No terms and conditions found");
    }
  };

  const fetchSignatures = async () => {
    const { data } = await Api.post("/signature/get/", {
      libraryId: opsOrder?.project?.wallet?.defaultLibrary,
    });

    setSignatures(data);
  };

  useEffect(() => {
    fetchTermsAndConditions();
    fetchSignatures();
  }, [opsOrder?.project?.wallet?.defaultLibrary]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const updatedData = {
        vendor: vendor?.value,
        project: selectedProject?.data?.parent?._id,
        advanceAmount: parseFloat(advanceAmount || 0),
        untaxedAmountVal: parseFloat(billListTotals?.taxExclTotalAmount || 0),
        totalAmountVal: parseFloat(billListTotals?.taxInclTotalAmount || 0),
        retentionPercentage: parseFloat(retantionPercentage || 0),
        termsAndConditions: editorContent,
        signatures: selectedSignatures.map((item) => item?._id),
      };

      await updateOpsOrderWithUpdate(opsOrder?._id, updatedData);
      setAlertObjValidation({
        show: true,
        message: "Data saved successfully",
        status: "success",
      });
      history.goBack();
    } catch (error) {
      console.error("Error saving data:", error);
      setAlertObjValidation({
        show: true,
        message: "Error saving data. Please try again.",
        status: "error",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleTermsChange = (event) => {
    const newTermsIds = event.target.value;
    const newTerms = termsAndConditions.filter((item) =>
      newTermsIds.includes(item._id)
    );
    setSelectedTerms(newTerms);

    const addedTerm = newTerms.find(
      (term) => !selectedTerms.find((t) => t._id === term._id)
    );

    if (addedTerm) {
      const newContent = addedTerm.content;
      const newTitle = addedTerm.title;
      setEditorContent(
        (prev) => prev + `<h3><b>${newTitle}</b></h3>` + newContent + "<br><br>"
      );
    }
  };

  const memoizedSelect = useMemo(
    () => (
      <Select
        fullWidth
        sx={{ padding: "10px" }}
        value={selectedTerms.map((item) => item._id)}
        onChange={handleTermsChange}
        multiple
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {selected.map((value) => {
              const selectedItem = termsAndConditions.find(
                (item) => item._id === value
              );
              return (
                <Typography variant="outlined" key={value}>
                  {selectedItem?.title || "Unknown"}
                </Typography>
              );
            })}
          </Box>
        )}
      >
        {termsAndConditions.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    ),
    [termsAndConditions, selectedTerms]
  );
  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={classes.barLeft}>
          <IconButton
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspaceIcon className={classes.backArrow} />
          </IconButton>
          <h3 className={classes.headerTitle}>{opsOrder?.name}</h3>
        </div>
        <div className={classes.barRight}>
          <BudgetUpdate
            selectedBudgetId={selectedBudgetId}
            setSelectedBudgetId={setSelectedBudgetId}
            addNewSelectedItems={addNewSelectedItems}
            budgetUpdateStatus={budgetUpdateStatus}
            workspaceType={"OpsOrder"}
            workspaceId={opsOrderId}
            projectProfileId={opsOrder?.project?.profile?._id}
            projectId={opsOrder?.project?._id}
            projectParentId={opsOrder?.projectParent}
            projectParentModel={opsOrder?.projectParentModel}
            projectBlockId={opsOrder?.projectBlock?._id}
            parentTotalAmount={billListTotals?.taxInclTotalAmount || 0}
          />
          <div style={{ width: "10px" }}></div>
          <Button
            id="demo-customized-button"
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              setOpenApprovalFlow(true);
            }}
            endIcon={<MdOutlineForkRight />}
          >
            Approval Flow
          </Button>
          <ApprovalAndActions
            openApprovalFlow={openApprovalFlow}
            setOpenApprovalFlow={setOpenApprovalFlow}
            loadingBool={loadingBool}
            setLoadingBool={setLoadingBool}
            parent={opsOrder?._id}
            parentModelName={"OpsOrder"}
            requestId={opsOrder?.approvalRequest?._id}
            needToMakeDisable={needToMakeStatusDisable}
            activeStage={activeStage}
            templateId={opsOrder?.approvalRequest?.approvalTemplate?._id}
            organizationId={opsOrder?.orgId}
            projectId={opsOrder?.project?._id}
            origin={"workorder"}
          />
          <div style={{ width: "10px" }}></div>
          {needToMakeStatusDisable ? (
            <Button
              id="demo-customized-button"
              variant="outlined"
              size="small"
              color="primary"
              disabled
              endIcon={<LockIcon />}
            >
              {opsOrderStatus ? opsOrderStatus : "STATUS"}
            </Button>
          ) : (
            <>
              <MyPopOver
                closeOnClick={true}
                appearContent={
                  <Button
                    id="demo-customized-button"
                    variant="outlined"
                    size="small"
                    color="primary"
                    endIcon={<ArrowDropDown />}
                  >
                    {opsOrderStatus ? opsOrderStatus : "STATUS"}
                  </Button>
                }
                showContent={
                  <div className={classes.optionsCont}>
                    {allStatusOptions[opsOrder?.type][opsOrder?.status] &&
                      allStatusOptions[opsOrder?.type][opsOrder?.status].map(
                        (statusOption, i) => (
                          <div
                            key={i}
                            className={classes.typeSingleOptionSty}
                            onClick={() => {
                              updateStatus(statusOption);
                            }}
                          >
                            {statusOption}
                          </div>
                        )
                      )}
                  </div>
                }
              />
              <ApprovalActionDrawer
                openAction={openAction}
                setOpenAction={setOpenAction}
                loadingBool={loadingBool}
                setLoadingBool={setLoadingBool}
                actionType={"takeAction"}
                origin={"workorder"}
                parent={opsOrder?._id}
                parentModelName={"OpsOrder"}
                statusField={"status"}
                statusValue={actionStatus}
                templateId={opsOrder?.approvalRequest?.approvalTemplate?._id}
                organizationId={opsOrder?.orgId}
                projectId={opsOrder?.project?._id}
                requestId={opsOrder?.approvalRequest?._id}
                activeStage={activeStage}
                actionCallBack={(type, curStatues) => {
                  getOpsOrder();
                }}
              />
            </>
          )}
          <ExportPDF
            apiUrl="/procurement/ops/order/pdf"
            fileName="order"
            apiBody={{
              opsOrderId: opsOrderId,
            }}
            enableSignatures={true}
            enableTermsAndConditions={true}
            libraryId={opsOrder?.project?.wallet?.defaultLibrary}
            profileId={opsOrder?.project?.profile}
          />
        </div>
      </div>
      <div style={{ width: "100%", height: "4px" }}>
        {loadingBool && <LinearProgress />}
      </div>
      <div className={classes.editBody}>
        <Paper className={classes.paperCont} elevation={2}>
          <div className={classes.paperHeader}>
            <h2>Basic Data</h2>
            <h2></h2>
          </div>

          <div className={classes.basicDataCont}>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={
                  <>
                    Vendor<span className={classes.starSty}>*</span>
                  </>
                }
                inputComp={
                  <div className="inputCont">
                    <PaginatedEntityDropdown
                      value={vendor}
                      disabled={needToMakeDisable}
                      onChange={(value) => {
                        console.log(value);
                        setVendor(value);
                      }}
                      isMulti={false}
                      financialRelationType={"Vendor"}
                      orgtype={"Organization"}
                      entity={"Project"}
                      curEntityId={projectId}
                      givenProfileIdArr={[]}
                      givenFilterOption={[
                        {
                          option: "Contact",
                          types: ["Contractor", "Vendor"],
                        },
                      ]}
                    />
                  </div>
                }
                validation={
                  validationObj?.vendor
                    ? validationObj?.vendor
                    : validationObj?.["vendor.value"]
                      ? validationObj?.["vendor.value"]
                      : ""
                }
              />
            </div>

            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={"Owner"}
                inputComp={
                  <div className="inputCont">
                    <div className={classes.selectedUserSty}>
                      <Avatar
                        src={
                          opsOrder?.projectParent?.displayPicture?.thumbUrl
                            ? opsOrder?.projectParent?.displayPicture?.thumbUrl
                            : opsOrder?.projectParent?.displayPicture?.url
                        }
                        alt={selectedProject?.data?.parent?.displayName}
                      />
                      <div style={{ marginLeft: "5px" }}>
                        <h3>{opsOrder?.projectParent?.displayName}</h3>
                        <p>{opsOrder?.projectParent?.username}</p>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={
                  <>
                    Project<span className={classes.starSty}>*</span>
                  </>
                }
                inputComp={
                  <div className="inputCont">
                    <PaginatedEntityDropdown
                      value={selectedProject}
                      onChange={(value) => {
                        setSelectedProject(value);
                      }}
                      isMulti={false}
                      entity={"Project"}
                      curEntityId={projectId}
                      givenProfileIdArr={[user?.profile]}
                      palCreate={false}
                      disabled={needToMakeDisable}
                      noFilter={true}
                      givenFilterOption={[
                        {
                          option: "Network",
                          types: ["Project"],
                        },
                      ]}
                    />
                  </div>
                }
                validation={
                  validationObj?.project
                    ? validationObj?.project
                    : validationObj?.["project.value"]
                      ? validationObj?.["project.value"]
                      : ""
                }
              />
            </div>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={
                  <>
                    Advance Amount<span className={classes.starSty}>*</span>
                  </>
                }
                inputComp={
                  <div className="inputCont">
                    <TextFieldNumberFormated
                      size="small"
                      disabled={needToMakeDisable}
                      placeholder="Enter Advance Amount"
                      autoComplete="off"
                      style={{ width: "100%" }}
                      value={advanceAmount}
                      onChange={(e) => {
                        setAdvanceAmount(e.target.value);
                      }}
                    />
                  </div>
                }
                validation={
                  validationObj?.["advance amount"]
                    ? validationObj?.["advance amount"]
                    : ""
                }
              />
            </div>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={
                  <>
                    Retention Percentage
                    <span className={classes.starSty}>*</span>
                  </>
                }
                inputComp={
                  <div className="inputCont">
                    <TextField
                      id="standard-basic"
                      size="small"
                      placeholder="Enter retention percentage"
                      autoComplete="off"
                      type="number"
                      variant="standard"
                      disabled={needToMakeDisable}
                      value={retantionPercentage}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FaPercent />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setRetantionPercentage(e.target.value);
                      }}
                    />
                  </div>
                }
                validation={
                  validationObj?.["retantion percentage"]
                    ? validationObj?.["retantion percentage"]
                    : ""
                }
              />
            </div>
          </div>
        </Paper>

        <Paper className={classes.paperCont} elevation={2}>
          <div className={classes.paperHeader}>
            <h2>Linked Documents</h2>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setOpenLinkedDocsDrawer(true)}
            >
              Manage Linked Docs
            </Button>
          </div>
          <Grid container spacing={3}>
            {linkedDocsDetails.length > 0 ? (
              linkedDocsDetails.map((doc) => (
                <Grid item xs={12} sm={6} md={4} key={doc._id}>
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        mb={2}
                      >
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ flex: 1 }}
                        >
                          {doc.name}
                        </Typography>
                        <Chip
                          label={doc.status}
                          color={
                            doc.status === "Approved" ? "primary" : "default"
                          }
                          size="small"
                          className={classes.chip}
                        />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <div className={classes.iconText}>
                          <Description fontSize="small" />
                          <Typography variant="body2">{doc.type}</Typography>
                        </div>
                        {!["Bill Of Quantities", "Material Request"].includes(
                          doc.type
                        ) && (
                            <Typography
                              variant="body2"
                              className={classes.boldText}
                            >
                              ₹{numberWithCommas(doc.totalAmountVal || 0)}
                            </Typography>
                          )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  No linked documents available.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>

        {certificates && certificates.length > 0 && (
          <Paper className={classes.paperCont} elevation={2}>
            <div
              className={classes.paperHeader}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <h2>Certificates</h2>
              <h2>
                ₹{numberWithCommas(parseFloat(totalCFAmount || 0).toFixed(2))} /
                ₹
                {numberWithCommas(
                  parseFloat(billListTotals?.taxInclTotalAmount || 0).toFixed(2)
                )}
              </h2>
              {(() => {
                const totalAmount = parseFloat(
                  billListTotals?.taxInclTotalAmount || 0
                );
                const subtractAmount = parseFloat(totalCFAmount || 0);
                const balance = totalAmount - subtractAmount;
                const balanceColor = balance >= 0 ? "green" : "red";

                return (
                  <h2 style={{ color: balanceColor }}>
                    Balance : ₹{numberWithCommas(Math.abs(balance).toFixed(2))}
                  </h2>
                );
              })()}
            </div>

            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Certificate No.</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Created By</TableCell>
                    <TableCell align="center">Created At</TableCell>
                    <TableCell align="center">Updated At</TableCell>
                    <TableCell align="right" style={{ width: "240px" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {certificates &&
                    certificates.map((cfData) => (
                      <TableRow key={cfData._id}>
                        <TableCell align="left">
                          {cfData?.certificateNumber}
                        </TableCell>
                        <TableCell align="center">
                          {numberWithCommas(
                            parseFloat(cfData?.netPayment || 0)
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <div className={classes.selectedUserOrgSty}>
                            <Avatar
                              src={
                                cfData?.user?.displayPicture?.thumbUrl
                                  ? cfData?.user?.displayPicture?.thumbUrl
                                  : cfData?.user?.displayPicture?.url
                              }
                              alt={cfData?.user?.displayName}
                            />
                            <div style={{ marginLeft: "5px" }}>
                              <h3>{cfData?.user?.displayName}</h3>
                              <p>{cfData?.user?.username}</p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {moment(cfData?.updatedAt).format("DD/MMM/YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {moment(cfData?.createdAt).format("DD/MMM/YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              if (cfData?.walletId) {
                                var path = isDev
                                  ? `/finance/${cfData?.walletId}/workorder-certificate/edit/${cfData?._id}`
                                  : `https://accounts.reallist.ai/finance/${cfData?.walletId}/workorder-certificate/edit/${cfData?._id}`;

                                if (isDev) {
                                  history.push(path);
                                } else {
                                  const token = localStorage.getItem("token");
                                  setAuthCookieData({ user, token });
                                  window.open(path, "_blank");
                                }
                              }
                            }}
                          >
                            View Details
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              if (cfData?.walletId) {
                                var path = isDev
                                  ? `/finance/${cfData?.walletId}/bill/edit/${cfData?.transaction}`
                                  : `https://accounts.reallist.ai/finance/${cfData?.walletId}/bill/edit/${cfData?.transaction}`;
                                if (isDev) {
                                  history.push(path);
                                } else {
                                  const token = localStorage.getItem("token");
                                  setAuthCookieData({ user, token });
                                  window.open(path, "_blank");
                                }
                              }
                            }}
                          >
                            View Bill
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}

        <Paper className={classes.paperCont} elevation={2}>
          <div className={classes.paperHeader}>
            <h2>Edit Items</h2>
            <h2></h2>
          </div>

          {opsOrder?.project?._id && opsOrder?.billList && (
            <BillListEdit
              billListId={opsOrder?.billList}
              wallet={wallet}
              projectProfileId={projectProfileId}
              needToMakeDisable={needToMakeDisable}
              setBillListError={setBillListError}
              setBillListTotals={setBillListTotals}
              billListTotals={billListTotals}
              setNeedToReloadBillList={setNeedToReloadBillList}
            />
          )}
        </Paper>

        <Paper className={classes.paperCont} elevation={2}>
          <BlocksAndTasksHandler
            tx={opsOrder}
            setTx={setOpsOrder}
            needToMakeDisable={needToMakeDisable}
            setLoadingBool={setLoadingBool}
            walletId={wallet?._id}
            selectProject={opsOrder?.project?._id}
            parentModel="OpsOrder"
          />
        </Paper>

        <Paper className={classes.paperCont} elevation={2}>
          <div className={classes.paperHeader}>
            <h2>Signatures.</h2>
          </div>
          <InputWrapper
            inputComp={
              <div
                className="inputCont"
                style={{
                  width: "100%",
                }}
              >
                <Select
                  fullWidth
                  sx={{ padding: "10px" }}
                  value={selectedSignatures.map((item) => item?._id)}
                  onChange={(event) => {
                    const newTerms = signatures.filter((item) =>
                      event.target.value.includes(item._id)
                    );
                    setSelectedSignatures(() => newTerms);
                  }}
                  multiple
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "no-wrap",
                        gap: 1,
                      }}
                    >
                      {selected.map((value, index) => {
                        const selectedItem = signatures.find(
                          (item) => item._id === value
                        );
                        return (
                          <Typography variant="outlined" key={value}>
                            {selectedItem?.title || "Unknown"}
                            {index === selected.length - 1 ? ".  " : ", "}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                >
                  {signatures.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            }
          />
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {selectedSignatures.map((item, index) => (
              <>
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    padding: "20px",
                    alignItems: "start",
                  }}
                >
                  <Typography variant="h6" color={"primary"}>
                    {index + 1}.
                  </Typography>
                  <Box>
                    <Typography variant="h6" color={"primary"}>
                      {item?.title}. {item?.language}{" "}
                    </Typography>
                    <Box
                      sx={{
                        marginTop: "1rem",
                      }}
                    >
                      <Table
                        size="small"
                        aria-label="ultra-compact signature fields table"
                        sx={{
                          "& .MuiTableCell-root": {
                            padding: "4px 8px",
                            fontSize: "0.75rem",
                            lineHeight: 1.2,
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                border: "1px solid black",
                                backgroundColor: "#f5f5f5",
                              }}
                            >
                              Designation
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                border: "1px solid black",
                                backgroundColor: "#f5f5f5",
                              }}
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item?.fields
                            ?.slice(0, 3)
                            .map((field, fieldIndex) => (
                              <TableRow
                                key={fieldIndex}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    borderBottom: 0,
                                  },
                                  "&:nth-of-type(even)": {
                                    backgroundColor: "#fafafa",
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    borderLeft: "1px solid black",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {field.name}
                                </TableCell>
                                <TableCell
                                  sx={{ borderRight: "1px solid black" }}
                                >
                                  {field.action}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                  <Tooltip title="Remove">
                    <IconButton
                      size="small"
                      onClick={() =>
                        setSelectedSignatures((prev) =>
                          prev.filter((tAndC) => item._id !== tAndC._id)
                        )
                      }
                      sx={{ color: "primary.contrastText" }}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider />
              </>
            ))}
          </div>
        </Paper>

        <Paper className={classes.paperCont} elevation={2}>
          <div className={classes.paperHeader}>
            <h2>Terms and conditions</h2>
          </div>
          <InputWrapper
            inputComp={
              <div
                className="inputCont"
                style={{
                  width: "100%",
                }}
              >
                {memoizedSelect}
              </div>
            }
          />
          <div
            style={{
              marginTop: "20px",
              maxHeight: "400px",
            }}
          >
            <ReactQuill
              theme="snow"
              style={{
                maxHeight: "400px",
                overflowY: "auto",
              }}
              value={editorContent}
              onChange={setEditorContent}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline"],
                  ["clean"],
                ],
              }}
            />
          </div>
        </Paper>

        {opsOrder?._id && (
          <Paper className={classes.paperCont} elevation={2}>
            <AttachmentsHandler
              parentData={opsOrder}
              setParentData={setOpsOrder}
              parentModel={"OpsOrder"}
              needToMakeDisable={needToMakeDisable}
              setLoadingBool={setLoadingBool}
            />
          </Paper>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <LoadingButton
              variant="contained"
              onClick={handleSave}
              loading={isSaving}
              disabled={needToMakeDisable || isSaving}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>

        <NotifyAlert
          alertObj={alertObjValidation}
          setAlertObj={setAlertObjValidation}
        />
        <LinkedDocsDrawer
          open={openLinkedDocsDrawer}
          onClose={() => setOpenLinkedDocsDrawer(false)}
          opsOrder={opsOrder}
          linkedDocs={opsOrder.linkedDocs || []}
          setLinkedDocs={handleLinkedDocsUpdate}
          profileId={projectProfileId}
          opsTypesList={["Material Request", "Bill Of Quantities"]}
          defaultopsType="Bill Of Quantities"
          selectOne={false}
        />
      </div>
      <div></div>
    </div>
  );
};

export default WorkorderEdit;
