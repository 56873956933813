import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, TextField, InputAdornment, Avatar, ClickAwayListener } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LinearProgress from "@material-ui/core/LinearProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Search } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import emptyPage from "../../../Assets/EmptyPageSVG.svg"
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import { createProcurementApiCall, getNamesByProfileIds, getOpsOrders, getOpsOrdersForFilter, getProfileDataPopulate, opsOrderDahsboardAggregate } from "./api.call";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../styled/agGrid.css";
import { useDebounce } from "react-use";
import moment from "moment";
import { createDefaultTemplateHook } from "../../approval/approvalHelper";
import { createCertificateApiCall, createTx } from "../../finance2o/commonComponent/transaction/api";
import LessText from "../../styled/CommonComponents/LessText"
import { Paper, Grid } from "@mui/material";
import { RiFileAddLine } from 'react-icons/ri';
import { FaClipboardList, FaLock, FaMoneyBillAlt } from 'react-icons/fa';
import { MdShoppingCart } from 'react-icons/md';
import { AiOutlineTool } from 'react-icons/ai';
import { FiPackage } from 'react-icons/fi';
import { GiDiploma } from 'react-icons/gi';
import { BsArrowUpRightCircle } from 'react-icons/bs';
import { IoNavigateOutline } from "react-icons/io5";
import PollIcon from '@material-ui/icons/Poll';
import ProcurementAnalysisDrawer from "./ProcurementAnalysisDrawer";
import TuneIcon from '@material-ui/icons/Tune';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getLinerStatuses } from "../../../helpers/approval";
import { GiTakeMyMoney } from "react-icons/gi";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        backgroundColor: "white",
    },
    topBar: {
        width: "100%",
        height: "60px",
        borderBottom: "1px solid #ececec",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    barLeft: {
        display: "flex",
        alignItems: "center"
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
        },
    },
    headerTitle: {
        fontSize: "18px",
        fontWeight: "550",
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
            fontWeight: "510",
        },
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        paddingRight: "20px",
        position: "relative",
        "& .MuiButton-label": {
            padding: "0px 1px",
            textTransform: "capitalize",
        }
    },
    optionsCont: {
        padding: "8px",
    },
    singleOption: {
        padding: "5px 10px",
        textAlign: "left",
        cursor: "pointer",
        fontSize: "15px",
        fontWeight: "500",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    loaderCont: {
        width: "100%",
        height: "5px",
    },
    bodyCont: {
        width: "100%",
        height: "calc(100vh - 65px)",
        overflow: "hidden",
        padding: "15px"
    },
    allCardCont: {
        width: "100%",
        overflowX: "auto",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: "15px",
        paddingBottom: "10px"
    },
    chartsAndTablesCont: {
        width: "100%",
        height: "calc(100% - 95px)"
    },
    cardCont: {
        display: "flex",
        flexGrow: "1",
        flexShrink: "0",
        alignItems: "center",
        padding: "8px 10px",
        margin: "0px 10px"
    },
    cardIcont: {
        width: "35px",
        height: "35px",
        fontSize: "22px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.main,
        color: "white"
    },
    cardDetails: {
        paddingLeft: "10px",
        "& h3": {
            fontSize: "16px",
            fontWeight: "600"
        },
        "& h4": {
            fontSize: "13px",
            fontWeight: "400",
            color: "gray"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #d2d1d1",
    },
    tableContainer: {
        width: "100%",
        height: "calc(100% - 50px)",
        "& tr": {
            height: "45px"
        },
        "& table": {
            tableLayout: "auto"
        }
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec !important"
        }
    },
    emptyLibrarayCont: {
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "200px",
            height: "auto",
        },
        "& div": {
            width: "100%",
            marginTop: "10px",
            padding: "0px 20px",
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray",
            textAlign: "center",
        },
        "& a": {
            textDecoration: "none",
            fontSize: "14px",
            marginLeft: "5px",
            cursor: "pointer",
            color: "blue"
        }
    },
    filterCont: {
        display: "block",
        position: "absolute",
        width: "500px",
        top: "45px",
        left: "35",
        padding: "15px",
        backgroundColor: "white",
        borderRadius: "5px",
        zIndex: "999",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "all 0.3s",
    },
    hideFilterCont: {
        display: "none",
        position: "absolute",
        width: "500px",
        top: "45px",
        left: "35",
        padding: "15px",
        backgroundColor: "white",
        borderRadius: "5px",
        zIndex: "999",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "all 0.3s",
    }
}));

const opsTypesList = ["Material Request", "Bill Of Quantities", "Purchase Order", "Work Order", "GRN", "Certificate", "Credit Note", "Debit Note"]

const ProcurementNew = (props) => {
    const { profileId } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [filterObj, setFilterObj] = useState({
        searchQuery: "",
        type: null,
        status: [],
        project: [],
        materialRequest: [],
        billOfQuantities: [],
        purchaseOrder: [],
        workOrder: [],
        GRN: []
    })
    const [showFilter, setShowFilter] = useState(false)
    const [allOpsOrdersForFilter, setAllOpsOrdersForFilter] = useState([])
    const [opsTypes, setOpsTypes] = useState([]);
    const [profileData, setProfileData] = useState(null)
    const [loader, setLoader] = useState(false)
    const [graphLoader, setGraphLoader] = useState(false)
    const [openProcurementAnalysis, setOpenProcurementAnalysis] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const [totalResultsCount, setTotalResultsCount] = useState(0)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [changeState, setChangeState] = useState()
    const [opsOrder, setOpsOrder] = useState([])
    const [defaultCreateLoader, setDefaultCreateLoader] = useState(false)
    const [pieChartDataArr, setPieChartDataArr] = useState([])
    const [topBarDataArr, setTopBarDataArr] = useState([])
    const [barChartsData, setBarChartsData] = useState([])
    const [vendorsIds, setVendorsIds] = useState([])
    const [vendorsArr, setVendorsArr] = useState([])

    const getAllProcurementName = async () => {
        await getOpsOrdersForFilter({
            identifier: profileData?.parent?._id
        })
            .then((data) => {
                setAllOpsOrdersForFilter(data?.data || [])
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (profileData?.parent?._id) {
            getAllProcurementName()
        }
    }, [profileData])

    //get details data of current scope
    const getProjectData = async () => {
        await getProfileDataPopulate({ profileId })
            .then((data) => {
                console.log(data)
                setProfileData(data)
                let accessableTypes = [];
                opsTypesList.map((type) => {
                    if (type === "Material Request") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultMaterialRequestApproval?.stages && data.parent.wallet.defaultLibrary.defaultMaterialRequestApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultMaterialRequestApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "Bill Of Quantities") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultBillOfQuantitiesApproval?.stages && data.parent.wallet.defaultLibrary.defaultBillOfQuantitiesApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultBillOfQuantitiesApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "Purchase Order") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultPurchaseOrderApproval?.stages && data.parent.wallet.defaultLibrary.defaultPurchaseOrderApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultPurchaseOrderApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "Work Order") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultWorkOrderApproval?.stages && data.parent.wallet.defaultLibrary.defaultWorkOrderApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultWorkOrderApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "GRN") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultGRNApproval?.stages && data.parent.wallet.defaultLibrary.defaultGRNApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultGRNApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "Certificate") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultFinancialCertificateApproval?.stages && data.parent.wallet.defaultLibrary.defaultFinancialCertificateApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultFinancialCertificateApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "Credit Note" || type === "Debit Note") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultDebitCreditNoteApproval?.stages && data.parent.wallet.defaultLibrary.defaultDebitCreditNoteApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultDebitCreditNoteApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    }
                })
                setOpsTypes(accessableTypes)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (profileId) {
            getProjectData()
        }
    }, [profileId])

    //final create api call
    const createProcurement = async (opsType) => {
        setLoader(true)
        if (opsType === "Certificate") {
            let createData = {
                user: user?._id,
                walletId: profileData?.parent?.wallet?._id,
                profile: profileData?._id,
                userProfile: user?.profile,
                libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
                defaultTemplate: profileData?.parent?.wallet?.defaultLibrary?.defaultFinancialCertificateApproval?._id,
            }
            if (profileData?.parentModelName === "Project") {
                createData.projectParent = profileData?.parent?.ownerProfile?.parent;
                createData.projectParentModel = profileData?.parent?.ownerProfile?.parentModelName;
                createData.project = profileData?.parent?._id;
            } else if (profileData?.parentModelName === "Organization") {
                createData.orgId = profileData?.parent?._id
            } else if (profileData?.parent?.ownerProfile?.parentModelName === "Organization") {
                createData.orgId = profileData?.parent?.ownerProfile?.parent
            }
            await createCertificateApiCall(createData)
                .then((data) => {
                    console.log(data)
                    history.push(`/procurements/${profileData?.parent?.wallet?._id}/workorder-certificate/edit/${data?._id}`);
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setLoader(false)
                })
        } else if (opsType === "Credit Note" || opsType === "Debit Note") {
            let type = "";
            if (opsType === "Credit Note") {
                type = "CreditNote";
            } else if (opsType === "Debit Note") {
                type = "DebitNote";
            }
            let createData = {
                type,
                status: "Draft",
                firstPartyWallet: profileData?.parent?.wallet?._id,
                firstParty: profileData?._id,
                user: user?._id,
                creatorUser: user?._id,
                creatorProfile: user?.profile,
                profile: profileData?._id,
                libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
                defaultTemplate: profileData?.parent?.wallet?.defaultLibrary?.defaultDebitCreditNoteApproval?._id,
                isAccountingNeed: false
            }
            if (profileData?.parentModelName === "Project") {
                createData.projectParent = profileData?.parent?.ownerProfile?.parent;
                createData.projectParentModel = profileData?.parent?.ownerProfile?.parentModelName;
                createData.project = profileData?.parent?._id;
            } else if (profileData?.parentModelName === "Organization") {
                createData.orgId = profileData?.parent?._id
            } else if (profileData?.parent?.ownerProfile?.parentModelName === "Organization") {
                createData.orgId = profileData?.parent?.ownerProfile?.parent
            }
            const data = await createTx(createData)
            setLoader(false)
            if (data?._id) {
                let path = "";
                if (type === "DebitNote") {
                    path = "/procurements/" + profileData?.parent?.wallet?._id + "/debit-note/edit/" + data?._id;
                } else if (type === "CreditNote") {
                    path = "/procurements/" + profileData?.parent?.wallet?._id + "/credit-note/edit/" + data?._id;
                }
                history.push(path);
            }
        } else {
            let newObj = {
                profile: profileData?._id,
                opsType: opsType,
                userProfile: user?.profile,
                libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
                userId: user._id
            };
            if (opsType === "Material Request") {
                newObj.defaultTemplate = profileData?.parent?.wallet?.defaultLibrary?.defaultMaterialRequestApproval;
            } else if (opsType === "Bill Of Quantities") {
                newObj.defaultTemplate = profileData?.parent?.wallet?.defaultLibrary?.defaultBillOfQuantitiesApproval;
            } else if (opsType === "Purchase Order") {
                newObj.defaultTemplate = profileData?.parent?.wallet?.defaultLibrary?.defaultPurchaseOrderApproval;
            } else if (opsType === "Work Order") {
                newObj.defaultTemplate = profileData?.parent?.wallet?.defaultLibrary?.defaultWorkOrderApproval;
            } else if (opsType === "GRN") {
                newObj.defaultTemplate = profileData?.parent?.wallet?.defaultLibrary?.defaultGRNApproval;
            }
            if (profileData?.parentModelName === "Project") {
                newObj.projectParent = profileData?.parent?.ownerProfile?.parent;
                newObj.projectParentModel = profileData?.parent?.ownerProfile?.parentModelName;
                newObj.project = profileData?.parent?._id;
            } else if (profileData?.parentModelName === "Organization") {
                newObj.orgId = profileData?.parent?._id
            } else if (profileData?.parent?.ownerProfile?.parentModelName === "Organization") {
                newObj.orgId = profileData?.parent?.ownerProfile?.parent
            }
            await createProcurementApiCall(newObj)
                .then((data) => {
                    console.log("data:", data);
                    history.push(`/procurements/ops-order/${data._id}/edit`);
                    dispatch({
                        type: "AddApiAlert",
                        payload: {
                            success: true,
                            message: `${opsType} created successfully`,
                        },
                    });
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                    setLoader(false)
                })
        }
    }

    const getAllProcurements = async () => {
        if (profileData && profileData?.parent?._id) {
            setLoader(true)
            await getOpsOrders({
                identifier: profileData?.parent?._id,
                filterObj,
                curPage: curPage - 1,
                limit: pageLimit
            })
                .then((data) => {
                    setOpsOrder(data?.data || [])
                    setTotalResultsCount(data?.count || 0)
                    let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
                    setTotalPages(locTotalPage)
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                    setLoader(false)
                })
        }
    }

    const getGraphData = async () => {
        if (profileData && profileData?.parent?._id) {
            setGraphLoader(true)
            await opsOrderDahsboardAggregate({
                identifier: profileData?.parent?._id
            })
                .then(async (data) => {
                    console.log(data)
                    let purchaseOrderAdvanceAmount = 0;
                    let workOrderAdvanceAmount = 0;
                    const locTopBarData = data?.totalOfOpsTypesAndCertificate || []
                    if (locTopBarData && locTopBarData.length > 0) {
                        let locTypesAmount = locTopBarData[0]?.types || []
                        let locWorkOrderAmount = 0
                        let locPurchaseAmount = 0
                        let locMaterialRequestAmount = 0
                        let locBillOfQuantitiesAmount = 0
                        let locGRNAmount = 0
                        locTypesAmount.map((s) => {
                            if (s?.type === "Work Order") {
                                locWorkOrderAmount = s?.totalAmount || 0
                                workOrderAdvanceAmount = s?.advanceAmount || 0
                            } else if (s?.type === "GRN") {
                                locGRNAmount = s?.totalAmount || 0
                            } else if (s?.type === "Purchase Order") {
                                locPurchaseAmount = s?.totalAmount || 0
                                purchaseOrderAdvanceAmount = s?.advanceAmount || 0
                            } else if (s?.type === "Bill Of Quantities") {
                                locBillOfQuantitiesAmount = s?.totalAmount || 0
                            } else if (s?.type === "Material Request") {
                                locMaterialRequestAmount = s?.totalAmount || 0
                            }
                        })
                        setTopBarDataArr({
                            advanceAmount: locTopBarData[0]?.advanceAmount,
                            certifiedAmount: locTopBarData[0]?.totalCertifiedAmount,
                            paidAmount: locTopBarData[0]?.totalPaidAmount,
                            retentionDeductionAmount: locTopBarData[0]?.retentionDeductionAmount,
                            retentionReleaseAmount: locTopBarData[0]?.retentionReleaseAmount,
                            advanceDeductionAmount: locTopBarData[0]?.advanceDeductionAmount,
                            workOrderAmount: locWorkOrderAmount,
                            purchaseAmount: locPurchaseAmount,
                            materialRequestAmount: locMaterialRequestAmount,
                            billOfQuantitiesAmount: locBillOfQuantitiesAmount,
                            grnAmount: locGRNAmount
                        })
                    }
                    let vendorIds = data?.vendorAndStatusWiseAmounts.map((s) => s?.vendor)
                    setVendorsIds(vendorIds)
                    const vendorsData = await getNamesByProfileIds({ vendorIds: vendorIds })
                    setVendorsArr(vendorsData)
                    let vendorNameMap = {}
                    vendorsData.map((s) => {
                        vendorNameMap[s?._id] = s?.parent?.displayName
                    })
                    let barChartsObj = {};
                    let locVendorAndStatusWiseAmounts = data?.vendorAndStatusWiseAmounts || []
                    locVendorAndStatusWiseAmounts.map((s) => {
                        console.log(s)
                        let locArrObj = barChartsObj?.[s?.type] || null
                        let obj = {
                            name: vendorNameMap?.[s?.vendor]
                        }
                        if (s?.totalCertifiedAmount !== null && s?.totalCertifiedAmount !== undefined && s?.totalCertifiedAmount !== 0) {
                            obj.certified = s?.totalCertifiedAmount
                        }
                        if (s?.totalPaidAmount !== null && s?.totalPaidAmount !== undefined && s?.totalPaidAmount !== 0) {
                            obj.paid = s?.totalPaidAmount
                        }
                        if (s?.retentionDeductionAmount !== null && s?.retentionDeductionAmount !== undefined && s?.retentionDeductionAmount !== 0) {
                            obj["Retention Deduction"] = s?.retentionDeductionAmount
                        }
                        if (s?.retentionReleaseAmount !== null && s?.retentionReleaseAmount !== undefined && s?.retentionReleaseAmount !== 0) {
                            obj["Retention Release"] = s?.retentionReleaseAmount
                        }
                        if (s?.advanceAmount !== null && s?.advanceAmount !== undefined && s?.advanceAmount !== 0) {
                            obj["Advance"] = s?.advanceAmount
                        }
                        if (s?.advanceDeductionAmount !== null && s?.advanceDeductionAmount !== undefined && s?.advanceDeductionAmount !== 0) {
                            obj["Advance Deduction"] = s?.advanceDeductionAmount
                        }
                        let locStatuses = s?.statuses || []
                        locStatuses.map((sa) => {
                            obj[sa?.status] = sa?.totalAmount
                        })

                        if (locArrObj === null) {
                            barChartsObj[s?.type] = {
                                title: s?.type,
                                totalAmount: s?.totalAmount,
                                data: [obj]
                            }
                        } else {
                            let pastData = locArrObj?.data || []
                            barChartsObj[s?.type] = {
                                ...locArrObj,
                                data: [...pastData, obj]
                            }
                        }
                    })
                    console.log(barChartsObj)
                    setBarChartsData(barChartsObj);

                    const typeStatusWiseAmounts = data?.typeStatusWiseAmounts || [];
                    let arr1 = [];
                    typeStatusWiseAmounts.map((s) => {
                        let subArr = s?.statuses ? s?.statuses.map((sa) => {
                            return {
                                name: sa?.status,
                                value: sa?.totalAmount || 0
                            }
                        }) : []
                        if (s?.type === "Work Order") {
                            arr1.push({
                                title: s?.type,
                                data: [
                                    {
                                        name: "Advance",
                                        value: workOrderAdvanceAmount || 0
                                    },
                                    ...subArr]
                            })
                        } else if (s?.type === "Purchase Order") {
                            arr1.push({
                                title: s?.type,
                                data: [
                                    {
                                        name: "Advance",
                                        value: purchaseOrderAdvanceAmount || 0
                                    },
                                    ...subArr]
                            })
                        } else {
                            arr1.push({
                                title: s?.type,
                                data: subArr
                            })
                        }
                    })
                    setPieChartDataArr(arr1)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setGraphLoader(false)
                })
        }
    }

    useEffect(() => {
        if (profileData && profileData?.parent?._id) {
            getGraphData()
        }
    }, [profileData, profileData?.parent?._id])

    useDebounce(() => {
        getAllProcurements()
    }, 1000, [curPage, profileData, changeState])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [filterObj, profileData, profileData?.parent?._id])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const createDefaultsTemplates = async () => {
        setDefaultCreateLoader(true)
        const entity = profileData?.parent?._id;
        const entityModelName = profileData?.parentModelName;
        const entityParent = profileData?.parent?.ownerProfile?.parent;
        const entityParentModelName = profileData?.parent?.ownerProfile?.parentModelName;
        await createDefaultTemplateHook({
            profileId: profileId,
            entity,
            entityModelName,
            entityParent,
            userId: user?._id,
            entityParentModelName,
            ownerProfileId: profileData?.parent?.ownerProfile?._id,
            types: ["Material Request", "Bill Of Quantities", "Purchase Order", "Work Order", "GRN", "Financial Certificate", "Debit/Credit Note"],
            libraryId: profileData?.parent?.wallet?.defaultLibrary?._id
        })
            .then(async (data) => {
                console.log(data)
                await getProjectData();
                setDefaultCreateLoader(false)
            })
            .catch(async (error) => {
                console.log(error)
                await getProjectData();
                setDefaultCreateLoader(false)
            })
    }

    return (<>
        {!profileData?.parent?.wallet?.defaultLibrary?._id ? (
            <div className={classes.emptyLibrarayCont} >
                <img src={emptyPage} />
                <div>
                    <p>
                        No Library exists in the current directory or any library still didn't selected as default library.<br />
                        Click <a
                            onClick={() => {
                                history.push(`/offerings-categories/management/${profileId}`)
                            }}
                        >here</a> to create and manage your library(s), category(s), product(s) and service(s)
                    </p>
                </div>
            </div>
        ) : (<>{(
            !profileData?.parent?.wallet?.defaultLibrary?.defaultMaterialRequestApproval ||
            !profileData?.parent?.wallet?.defaultLibrary?.defaultBillOfQuantitiesApproval ||
            !profileData?.parent?.wallet?.defaultLibrary?.defaultPurchaseOrderApproval ||
            !profileData?.parent?.wallet?.defaultLibrary?.defaultWorkOrderApproval ||
            !profileData?.parent?.wallet?.defaultLibrary?.defaultGRNApproval) ? (
            <div className={classes.emptyLibrarayCont} >
                <img src={emptyPage} />
                <div>
                    <p>{defaultCreateLoader ? `Template Creating....` : (<>
                        Some template(s) are missing in the current directory or some template(s) are still didn't selected as default.<br />
                        Click <a
                            onClick={createDefaultsTemplates}
                        >here</a> to create default template(s)
                    </>)}
                    </p>
                </div>
            </div>
        ) : (
            <div className={classes.root} >
                <div className={classes.topBar} >
                    <div className={classes.barLeft} >
                        <IconButton onClick={() => { history.goBack() }} >
                            <KeyboardBackspaceIcon className={classes.backArrow} />
                        </IconButton>
                        <h3 className={classes.headerTitle}>Procurements({totalResultsCount})</h3>
                    </div>
                    <div className={classes.barRight} >
                        <ClickAwayListener
                            onClickAway={() => {
                                setShowFilter(false)
                            }}
                        >
                            <div>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    placeholder="Search..."
                                    value={filterObj.searchQuery}
                                    style={{ width: "300px", marginRight: "10px" }}
                                    onChange={(e) => {
                                        setFilterObj({
                                            ...filterObj,
                                            searchQuery: e.target.value
                                        })
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setShowFilter(!showFilter)
                                                }}
                                            >
                                                <TuneIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div className={showFilter ? classes.filterCont : classes.hideFilterCont} >
                                    <Grid container spacing={2} gridColumn={12}>
                                        <Grid item xs={6} >
                                            <Autocomplete
                                                value={filterObj?.type}
                                                options={["Material Request", "Bill Of Quantities", "Purchase Order", "Work Order", "GRN"]}
                                                getOptionLabel={(option) => { return option || "" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        style={{ margin: "0px" }}
                                                        margin="normal"
                                                        variant="outlined"
                                                        label="Type"
                                                        placeholder="Select type"
                                                    />
                                                )}
                                                onChange={(e, value) => {
                                                    setFilterObj({
                                                        ...filterObj,
                                                        type: value,
                                                        status: [],
                                                        materialRequest: [],
                                                        billOfQuantities: [],
                                                        purchaseOrder: [],
                                                        workOrder: [],
                                                        GRN: []
                                                    })
                                                }}
                                                fullWidth
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Autocomplete
                                                value={filterObj?.status}
                                                options={getLinerStatuses(filterObj?.type)}
                                                getOptionLabel={(option) => { return option || "" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        style={{ margin: "0px" }}
                                                        margin="normal"
                                                        variant="outlined"
                                                        label="Status"
                                                        placeholder="Select status"
                                                    />
                                                )}
                                                multiple={true}
                                                disabled={filterObj?.type ? false : true}
                                                onChange={(e, value) => {
                                                    setFilterObj({
                                                        ...filterObj,
                                                        status: value
                                                    })
                                                }}
                                                fullWidth
                                                size="small"
                                            />
                                        </Grid>
                                        {(filterObj?.type === "Purchase Order" || filterObj?.type === "Work Order") && (
                                            <Grid item xs={12} >
                                                <Autocomplete
                                                    value={filterObj?.materialRequest}
                                                    options={allOpsOrdersForFilter.filter((s) => s.type === "Material Request")}
                                                    getOptionLabel={(option) => { return option?.name || "" }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            style={{ margin: "0px" }}
                                                            margin="normal"
                                                            variant="outlined"
                                                            label="Material Request"
                                                            placeholder="Select material request"
                                                        />
                                                    )}
                                                    multiple={true}
                                                    onChange={(e, value) => {
                                                        setFilterObj({
                                                            ...filterObj,
                                                            materialRequest: value
                                                        })
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Grid>
                                        )}
                                        {filterObj?.type === "Work Order" && (
                                            <Grid item xs={12} >
                                                <Autocomplete
                                                    value={filterObj?.billOfQuantities}
                                                    options={allOpsOrdersForFilter.filter((s) => s.type === "Bill Of Quantities")}
                                                    getOptionLabel={(option) => { return option?.name || "" }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            style={{ margin: "0px" }}
                                                            margin="normal"
                                                            variant="outlined"
                                                            label="Bill Of Quantities"
                                                            placeholder="Select bill of quantities"
                                                        />
                                                    )}
                                                    multiple={true}
                                                    onChange={(e, value) => {
                                                        setFilterObj({
                                                            ...filterObj,
                                                            billOfQuantities: value
                                                        })
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Grid>
                                        )}
                                        {(filterObj?.type === "Material Request" || filterObj?.type === "GRN") && (
                                            <Grid item xs={12} >
                                                <Autocomplete
                                                    value={filterObj?.purchaseOrder}
                                                    options={allOpsOrdersForFilter.filter((s) => s.type === "Purchase Order")}
                                                    getOptionLabel={(option) => { return option?.name || "" }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            style={{ margin: "0px" }}
                                                            margin="normal"
                                                            variant="outlined"
                                                            label="Purchase Order"
                                                            placeholder="Select purchase order"
                                                        />
                                                    )}
                                                    multiple={true}
                                                    onChange={(e, value) => {
                                                        setFilterObj({
                                                            ...filterObj,
                                                            purchaseOrder: value
                                                        })
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Grid>
                                        )}
                                        {(filterObj?.type === "Material Request" || filterObj?.type === "Bill Of Quantities") && (
                                            <Grid item xs={12} >
                                                <Autocomplete
                                                    value={filterObj?.workOrder}
                                                    options={allOpsOrdersForFilter.filter((s) => s.type === "Work Order")}
                                                    getOptionLabel={(option) => { return option?.name || "" }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            style={{ margin: "0px" }}
                                                            margin="normal"
                                                            variant="outlined"
                                                            label="Work Order"
                                                            placeholder="Select work order"
                                                        />
                                                    )}
                                                    multiple={true}
                                                    onChange={(e, value) => {
                                                        setFilterObj({
                                                            ...filterObj,
                                                            workOrder: value
                                                        })
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Grid>
                                        )}
                                        {filterObj?.type === "Purchase Order" && (
                                            <Grid item xs={12} >
                                                <Autocomplete
                                                    value={filterObj?.GRN}
                                                    options={allOpsOrdersForFilter.filter((s) => s.type === "GRN")}
                                                    getOptionLabel={(option) => { return option?.name || "" }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            style={{ margin: "0px" }}
                                                            margin="normal"
                                                            variant="outlined"
                                                            label="GRN"
                                                            placeholder="Select GRN"
                                                        />
                                                    )}
                                                    multiple={true}
                                                    onChange={(e, value) => {
                                                        setFilterObj({
                                                            ...filterObj,
                                                            GRN: value
                                                        })
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} >
                                            <Autocomplete
                                                value={filterObj?.project}
                                                options={[]}
                                                getOptionLabel={(option) => { return option || "" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        style={{ margin: "0px" }}
                                                        margin="normal"
                                                        variant="outlined"
                                                        label="Project"
                                                        placeholder="Select project"
                                                    />
                                                )}
                                                multiple={true}
                                                onChange={(e, value) => {
                                                    setFilterObj({
                                                        ...filterObj,
                                                        project: value
                                                    })
                                                }}
                                                fullWidth
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </ClickAwayListener>
                        {opsTypes && opsTypes.length > 0 ? (
                            <MyPopOver
                                closeOnClick={true}
                                appearContent={
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={loader}
                                        endIcon={<ExpandMoreIcon />}
                                    >
                                        Create
                                    </Button>
                                }
                                showContent={
                                    <div className={classes.optionsCont} >
                                        {opsTypes.map((item) => (
                                            <div
                                                className={classes.singleOption}
                                                onClick={() => { createProcurement(item) }}
                                            >
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                }
                            />
                        ) : null}
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<PollIcon />}
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                                setOpenProcurementAnalysis(true)
                            }}
                        >
                            Analysis
                        </Button>
                        <ProcurementAnalysisDrawer
                            openProcurementAnalysis={openProcurementAnalysis}
                            setOpenProcurementAnalysis={setOpenProcurementAnalysis}
                            pieChartDataArr={pieChartDataArr}
                            barChartsData={barChartsData}
                            vendorsArr={vendorsArr}
                            vendorsIds={vendorsIds}
                            profileData={profileData}
                        />
                    </div>
                </div>
                <div className={classes.loaderCont} >
                    {loader && (<LinearProgress />)}
                </div>
                <div className={classes.bodyCont} >
                    <div className={classes.allCardCont} >
                        <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <RiFileAddLine />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Work Order</h3>
                                <h4>₹{numberWithCommas(parseFloat(topBarDataArr?.workOrderAmount || 0).toFixed(2))}</h4>
                            </div>
                        </Paper>
                        {/* <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <FaClipboardList />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Material Request</h3>
                                <h4>₹{numberWithCommas(parseFloat(topBarDataArr?.materialRequestAmount || 0).toFixed(2))}</h4>
                            </div>
                        </Paper> */}
                        {/* <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <MdShoppingCart />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Bill Of Quantities</h3>
                                <h4>₹{numberWithCommas(parseFloat(topBarDataArr?.billOfQuantitiesAmount || 0).toFixed(2))}</h4>
                            </div>
                        </Paper> */}
                        <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <AiOutlineTool />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Purchase Order</h3>
                                <h4>₹{numberWithCommas(parseFloat(topBarDataArr?.purchaseAmount || 0).toFixed(2))}</h4>
                            </div>
                        </Paper>
                        {/* <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <FiPackage />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>GRN</h3>
                                <h4>₹{numberWithCommas(parseFloat(topBarDataArr?.grnAmount || 0).toFixed(2))}</h4>
                            </div>
                        </Paper> */}
                        <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <GiTakeMyMoney />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Advance</h3>
                                <h4>₹{numberWithCommas(parseFloat(topBarDataArr?.advanceAmount || 0).toFixed(2))}</h4>
                            </div>
                        </Paper>
                        <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <FaMoneyBillAlt />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Advance Deduction</h3>
                                <h4>₹{numberWithCommas(parseFloat(topBarDataArr?.advanceDeductionAmount || 0).toFixed(2))}</h4>
                            </div>
                        </Paper>
                        <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <FaClipboardList />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Advance Balance</h3>
                                <h4>₹{numberWithCommas((parseFloat(topBarDataArr?.advanceAmount || 0) - parseFloat(topBarDataArr?.advanceDeductionAmount || 0)).toFixed(2))}</h4>
                            </div>
                        </Paper>
                        <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <FaLock />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Retention Deduction</h3>
                                <h4>₹{numberWithCommas(parseFloat(topBarDataArr?.retentionDeductionAmount || 0).toFixed(2))}</h4>
                            </div>
                        </Paper>
                        <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <IoNavigateOutline />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Retention Release</h3>
                                <h4>₹{numberWithCommas(parseFloat(topBarDataArr?.retentionReleaseAmount || 0).toFixed(2))}</h4>
                            </div>
                        </Paper>
                        <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <MdShoppingCart />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Retention Balance</h3>
                                <h4>₹{numberWithCommas((parseFloat(topBarDataArr?.retentionDeductionAmount || 0) - parseFloat(topBarDataArr?.retentionReleaseAmount || 0)).toFixed(2))}</h4>
                            </div>
                        </Paper>
                        <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <GiDiploma />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Certificate</h3>
                                <h4>₹{numberWithCommas(parseFloat(topBarDataArr?.certifiedAmount || 0).toFixed(2))}</h4>
                            </div>
                        </Paper>
                        <Paper className={classes.cardCont} >
                            <div className={classes.cardIcont} >
                                <BsArrowUpRightCircle />
                            </div>
                            <div className={classes.cardDetails} >
                                <h3>Paid</h3>
                                <h4>₹{numberWithCommas(parseFloat(topBarDataArr?.paidAmount || 0).toFixed(2))}</h4>
                            </div>
                        </Paper>
                    </div>
                    <Paper className={classes.chartsAndTablesCont} elevation={2} >
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="sticky table" size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" style={{ minWidth: "170px" }} >
                                            Name
                                        </TableCell>
                                        <TableCell align="left" style={{ minWidth: "100px" }} >
                                            Status
                                        </TableCell>
                                        <TableCell align="left" style={{ minWidth: "120px" }} >
                                            Total Amount
                                        </TableCell>
                                        <TableCell align="left" style={{ minWidth: "230px" }} >
                                            Project
                                        </TableCell>
                                        <TableCell align="right" style={{ minWidth: "120px" }} >
                                            Created At
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {opsOrder && opsOrder.length > 0 && opsOrder.map((dataRow) => {
                                        return (
                                            <TableRow
                                                className={classes.tableRow}
                                                onClick={() => {
                                                    history.push(`/procurements/ops-order/${dataRow?._id}/edit`)
                                                }}
                                            >
                                                <TableCell align="left" style={{ minWidth: "170px" }} >
                                                    {dataRow?.name}
                                                </TableCell>
                                                <TableCell align="left" style={{ minWidth: "100px" }} >
                                                    {dataRow?.status}
                                                </TableCell>
                                                <TableCell align="left" style={{ minWidth: "120px" }} >
                                                    ₹{numberWithCommas(dataRow?.totalAmountVal || 0)}
                                                </TableCell>
                                                <TableCell align="left" style={{ minWidth: "230px" }} >
                                                    <LessText
                                                        limit={15}
                                                        string={dataRow?.project?.displayName || ""}
                                                    />
                                                </TableCell>
                                                <TableCell align="right" style={{ minWidth: "120px" }} >
                                                    {moment(dataRow?.created).format("DD/MMM/YYYY")}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.paginationCont} >
                            <Pagination
                                count={totalPages}
                                page={curPage}
                                color="primary"
                                siblingCount={0}
                                onChange={(event, value) => { setCurPage(value) }}
                            />
                        </div>
                    </Paper>
                </div>
            </div>
        )}
        </>
        )}
    </>
    );
};

export default ProcurementNew;