import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import BasicPrivateRoute from "../components/PrivateRoute/BasicPrivateRoute";
import Procurement from "../components/team/procurements/Procurement";
import ProcurementNew from "../components/team/procurements/Procurement.New";
import OpsOrderEdit from "../components/team/procurements/ops.order.edit.js";
import GRN from "../components/team/procurements/GRN";
import CertificateEdit from "../components/finance2o/Ops.Certificates/Certificate.Edit.js";
import TxEdit from "../components/finance2o/commonComponent/transaction/transaction.edit.js";

const Routes = (props) => {
  return [
    <BasicPrivateRoute
      exact
      path="/procurements/management/:profileId"
      noAppbar={true}
      useBothSide
      component={ProcurementNew}
    />,
    <BasicPrivateRoute
      exact
      path="/procurements"
      noAppbar={true}
      useBothSide
      component={Procurement}
    />,
    <BasicPrivateRoute
      exact
      path="/procurements/ops-order/:opsOrderId/edit"
      noAppbar={true}
      useBothSide
      component={OpsOrderEdit}
    />,
    <BasicPrivateRoute
      exact
      path="/procurements/grn/:opsId/"
      noAppbar={true}
      useBothSide
      component={GRN}
    />,
    <BasicPrivateRoute
      exact
      path="/procurements/:walletId/workorder-certificate/edit/:certificateId"
      noAppbar={true}
      useBothSide
      component={CertificateEdit}
    />,
    <BasicPrivateRoute
      exact
      path="/procurements/:walletId/debit-note/edit/:txId"
      noAppbar={true}
      useBothSide
      component={TxEdit}
    />,
    <BasicPrivateRoute
      exact
      path="/procurements/:walletId/credit-note/edit/:txId"
      noAppbar={true}
      useBothSide
      component={TxEdit}
    />
  ];
};
export default Routes;