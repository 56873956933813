import { Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import React, { useState, useCallback, useEffect } from "react";
import { PieChart, Tooltip, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { makeStyles } from "@material-ui/core/styles";

const COLORS = {
    "Draft": "#9B9B9B",         // Grey
    "Submitted": "#FF8C00",     // Dark Orange
    "Under Review": "#FF6347",  // Tomato Red
    "Approved": "#4682B4",      // Steel Blue
    "Rejected": "#FF4500",      // Orange Red
    "Evaluated": "#DA70D6",     // Orchid
    "Issued": "#32CD32",        // Lime Green
    "Acknowledged": "#1E90FF",  // Dodger Blue
    "Partially Fulfilled": "#FFD700", // Gold
    "Fully Fulfilled": "#2E8B57",    // Sea Green
    "Paid": "#87CEEB",          // Sky Blue
    "Partially Paid": "#FF69B4",     // Hot Pink
    "Sent": "#DDA0DD",         // Plum
    "Checking": "#FFA07A",      // Light Salmon
    "Cancelled": "#C0C0C0",    // Silver
    "Confirmed": "#4169E1",    // Royal Blue
    "In Progress": "#F4A460",   // Sandy Brown
    "Certified": "#20B2AA"     // Light Sea Green
}

const useStyles = makeStyles((theme) => ({
    pieMainCont: {
        width: "330px",
        height: "300px",
        marginTop: "10px",
        flexGrow: "1",
        flexShrink: "0",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500"
        }
    }
}));

function AmountStatusPieGraph({ data }) {
    const classes = useStyles();

    const [filteredArr, setFilteredArr] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        let filteredObj = [];
        let arrWithPercentage = []
        let total = 0;

        data?.data && data?.data.length > 0 && data?.data.map((d) => {
            if (d?.value !== 0) {
                filteredObj.push(d)
            }
            total += parseFloat(d?.value)
        })

        filteredObj.map((filterD) => {
            let perc = (((parseFloat(filterD?.value)) / total) * 100).toFixed(0);
            arrWithPercentage.push({
                ...filterD,
                value: parseFloat(filterD?.value),
                percentage: perc
            })
        })
        setFilteredArr(arrWithPercentage)
    }, [data?.data])

    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <Paper elevation={1} style={{ backgroundColor: "#ffffff", borderRadius: "3px", padding: "3px 8px" }} >
                    <Typography style={{ fontSize: "15px", fontWeight: "530" }} >{payload[0]?.name}</Typography>
                    <Typography style={{ fontSize: "13px" }} >{`₹ ${payload[0]?.value}  (${payload[0]?.payload?.percentage}%)`}</Typography>
                </Paper>
            );
        }
        return null;
    };

    const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        );
    };

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index
    }) => {

        const percentageVal = (percent * 100).toFixed(0)
        let radius
        if (percentageVal > 6) {
            radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        } else {
            radius = innerRadius + (outerRadius - innerRadius) * 0.8;
        }
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={"middle"}
                dominantBaseline="middle"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div className={classes.pieMainCont} >
            <h3>{data?.title}</h3>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={filteredArr}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                        onMouseEnter={onPieEnter}
                        isAnimationActive={false}
                    >
                        {filteredArr.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS?.[entry?.name]} />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}

export default AmountStatusPieGraph;