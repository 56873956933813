import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NormalDrawer from "../../styled/CommonComponents/NormalDrawer";
import AmountStatusPieGraph from "./AmountStatusPieChart";
import AmountVendorStatusChart from "./AmountVendorStatusChart";
import ProcurementVendors from "./ProcurementVendors";
import { Paper } from "@mui/material";

const COLORS = {
    "Advance": "#000",
    "Draft": "#9B9B9B",         // Grey
    "Submitted": "#FF8C00",     // Dark Orange
    "Under Review": "#FF6347",  // Tomato Red
    "Approved": "#4682B4",      // Steel Blue
    "Rejected": "#FF4500",      // Orange Red
    "Evaluated": "#DA70D6",     // Orchid
    "Issued": "#32CD32",        // Lime Green
    "Acknowledged": "#1E90FF",  // Dodger Blue
    "Partially Fulfilled": "#FFD700", // Gold
    "Fully Fulfilled": "#2E8B57",    // Sea Green
    "Paid": "#87CEEB",          // Sky Blue
    "Partially Paid": "#FF69B4",     // Hot Pink
    "Sent": "#DDA0DD",         // Plum
    "Checking": "#FFA07A",      // Light Salmon
    "Cancelled": "#C0C0C0",    // Silver
    "Confirmed": "#4169E1",    // Royal Blue
    "In Progress": "#F4A460",   // Sandy Brown
    "Certified": "#20B2AA",     // Light Sea Green
    "Retention Deduction": "purple",
    "Retention Release": "green",
    "Advance Deduction": "brown"
}

const useStyles = makeStyles((theme) => ({
    bodyCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex"
    },
    rightSide: {
        width: "400px",
        height: "100%",
        overflowY: "auto",
        padding: "20px",
        paddingLeft: "10px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    leftSide: {
        width: "calc(100% - 400px)",
        height: "100%",
        overflowY: "auto",
        padding: "20px",
        paddingRight: "10px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    pieChartsCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        overflowY: "hidden",
        overflowX: "auto",
        padding: "0px 20px",
        marginBottom: "20px"
    },
    barCharts: {
        width: "100%"
    },
    colorsCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        padding: "10px",
        marginBottom: "20px"
    },
    singleColorCont: {
        display: "flex",
        alignItems: "center",
        padding: "7px",
        "& div": {
            width: "15px",
            height: "15px",
            marginRight: "3px",
            border: "1px solid #ececec"
        },
        "& p": {
            fontSize: "14px",
            color: "gray",
            fontWeight: "500"
        }
    },
}));

const ProcurementAnalysisDrawer = ({
    openProcurementAnalysis, setOpenProcurementAnalysis, pieChartDataArr,
    barChartsData, vendorsArr, vendorsIds, profileData
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <NormalDrawer
            openDrawer={openProcurementAnalysis}
            setOpenDrawer={setOpenProcurementAnalysis}
            anchor={"right"}
            width={"calc(100vw - 120px)"}
            title={"Procurement Analysis"}
            content={
                <div className={classes.bodyCont} >
                    {openProcurementAnalysis && (<>
                        <div className={classes.leftSide} >
                            <Paper className={classes.pieChartsCont} elevation={2} >
                                {pieChartDataArr.map((data) => (
                                    <AmountStatusPieGraph data={data} />
                                ))}
                            </Paper>
                            <div className={classes.barCharts} >
                                {Object.keys(barChartsData).map((s) => {
                                    let data = barChartsData?.[s]
                                    return (
                                        <AmountVendorStatusChart
                                            data={data}
                                            vendorsArr={vendorsArr}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                        <div className={classes.rightSide} >
                            <Paper className={classes.colorsCont} elevation={2} >
                                {Object.keys(COLORS).map((key) => (
                                    <div className={classes.singleColorCont} >
                                        <div style={{ backgroundColor: COLORS?.[key] }} ></div>
                                        <p>{key}</p>
                                    </div>
                                ))}
                            </Paper>
                            {(vendorsIds && vendorsIds.length > 0) && (
                                <ProcurementVendors
                                    vendorProfileIds={vendorsIds}
                                    mainProfileId={profileData?._id}
                                    curEntityId={profileData?.parent?._id}
                                    entity={profileData?.parentModelName}
                                    walletId={profileData?.parent?.wallet?._id}
                                />
                            )}
                        </div>
                    </>)}
                </div>
            }
        />
    );
};

export default ProcurementAnalysisDrawer;