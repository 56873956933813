import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { makeStyles } from "@material-ui/core/styles";
import CustomSelectAutocomplete from "../../styled/CommonComponents/CustomSelectAutocomplete";

const useStyles = makeStyles((theme) => ({
    gpMainCont: {
        width: "100%",
        padding: "20px 20px 0px 0px",
        marginTop: "20px",
        "& .recharts-wrapper": {
            width: "100%"
        },
        "& h3": {
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "5px",
            marginLeft: "20px"
        },
        "& .recharts-tooltip-wrapper": {
            zIndex: "99999"
        }
    },
    selectCont: {
        width: "100%",
        paddingLeft: "20px"
    },
}));

export default function AmountVendorStatusChart({
    data, vendorsArr
}) {
    const classes = useStyles();
    const [mapData, setMapData] = useState([])
    const [height, setHeight] = useState(300)
    const [optionVendors, setOptionVendors] = useState([])
    const [selectedVendors, setSelectedVendors] = useState([])

    useEffect(() => {
        let arr = data?.data || [];
        let filteredOptions = [];
        arr.map((s) => {
            vendorsArr.map((vn) => {
                if (vn?.parent?.displayName === s?.name) {
                    filteredOptions.push(vn)
                }
            })
        })
        setOptionVendors(filteredOptions)
        if (filteredOptions && filteredOptions.length > 3) {
            setSelectedVendors(filteredOptions.slice(0, 3))
        } else {
            setSelectedVendors(filteredOptions)
        }
    }, [vendorsArr, data?.data])

    useEffect(() => {
        let arr = data?.data || [];
        let selectedVendorsName = selectedVendors.map((s) => s?.parent?.displayName)
        let result = [];
        arr.map((s) => {
            if (selectedVendorsName.includes(s?.name)) {
                result.push({
                    name: s?.name,
                    Draft: s?.Draft || 0,
                    Submitted: s?.Submitted || 0,
                    Approved: s?.Approved || 0,
                    Advance: s?.Advance || 0,
                    Rejected: s?.Rejected || 0,
                    Issued: s?.Issued || 0,
                    Acknowledged: s?.Acknowledged || 0,
                    "Partially Fulfilled": s?.["Partially Fulfilled"] || 0,
                    "Fully Fulfilled": s?.["Fully Fulfilled"] || 0,
                    "Retention Deduction": s?.["Retention Deduction"] || 0,
                    "Retention Release": s?.["Retention Release"] || 0,
                    "Advance Deduction": s?.["Advance Deduction"] || 0,
                    Certified: s?.certified || 0,
                    Paid: s?.paidAmount || 0
                })
            }
        })
        console.log(result)
        setMapData(result)
        setHeight(result.length * 240)
    }, [selectedVendors])

    return (
        <Paper elevation={2} className={classes.gpMainCont}  >
            <h3>{data?.title}</h3>
            <div className={classes.selectCont} >
                <CustomSelectAutocomplete
                    value={selectedVendors}
                    onChange={(value) => {
                        console.log(value)
                        setSelectedVendors(value);
                    }}
                    isMulti={true}
                    options={optionVendors}
                    fullWidth={true}
                    placeholder={"Select Vendor"}
                    getLabel={(s) => s?.parent?.displayName}
                    getIdentity={(s) => s?._id}
                    getImage={(s) => s?.parent?.displayPicture?.url}
                    getDisplayName={(s) => s?.parent?.displayName}
                    getUsername={(s) => s?.parent?.username}
                />
            </div>
            <div style={{ width: "100%", height: "20px" }} ></div>
            <div style={{ width: "100%", height: "100%" }}>
                <ResponsiveContainer width="100%" height={height}>
                    <ComposedChart
                        layout="vertical"
                        width={500}
                        height={400}
                        data={mapData}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis type="number" />
                        <YAxis dataKey="name" type="category" scale="band" />
                        <Tooltip />
                        <Bar dataKey="Advance" barSize={10} fill="#000000" />
                        <Bar dataKey="Draft" barSize={10} fill="#9B9B9B" />
                        <Bar dataKey="Submitted" barSize={10} fill="#FF8C00" />
                        <Bar dataKey="Approved" barSize={10} fill="#4682B4" />
                        <Bar dataKey="Retention Deduction" barSize={10} fill="purple" />
                        <Bar dataKey="Retention Release" barSize={10} fill="green" />
                        <Bar dataKey="Advance Deduction" barSize={10} fill="brown" />
                        <Bar dataKey="Issued" barSize={10} fill="#32CD32" />
                        <Bar dataKey="Acknowledged" barSize={10} fill="#1E90FF" />
                        <Bar dataKey="Partially Fulfilled" barSize={10} fill="#FFD700" />
                        <Bar dataKey="Fully Fulfilled" barSize={10} fill="#2E8B57" />
                        <Bar dataKey="Certified" barSize={10} fill="#20B2AA" />
                        <Bar dataKey="Paid" barSize={10} fill="#87CEEB" />
                        <Bar dataKey="Rejected" barSize={10} fill="#FF4500" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </Paper>
    );
}
