import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Avatar, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { getProcurementVendors } from "./api.call";
import emptyIcon from "../../../Assets/EmptyInboxOutline.svg";
import LessText from "../../styled/CommonComponents/LessText";
import ContactAnalysis from "../../contacts/ContactAnalysis";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxHeight: "400px",
        padding: "20px",
        marginBottom: "20px",
        "& h4": {
            fontSize: "18px",
            fontWeight: "600"
        }
    },
    allCardsContStyle: {
        width: "100%",
        height: "calc(100% - 40px)",
        overflowY: "auto",
        paddingRight: "0px",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        alignContent: "flex-start",
        justifyContent: "space-between",
    },
    singleCardStyle: {
        width: "100%",
        textAlign: "center",
        borderRadius: "5px",
        padding: "10px 10px",
        marginTop: "15px",
        position: "relative",
        zIndex: "11",
        border: "1px solid #ececec",
        display: "flex",
        alignItems: "center",
    },
    cardSkeletonStyle: {
        width: "100%",
        height: "70px",
        borderRadius: "5px",
        marginBottom: "20px",
    },
    infoCont: {
        marginLeft: "5px",
        "& h3": {
            fontSize: "15px",
            fontWeight: "510",
            textAlign: "left",
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "450",
            color: "gray",
            textAlign: "left",
        },
    },
    emptyCont: {
        width: "100%",
        height: "70%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& img": {
            width: "150px",
            height: "auto",
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray",
        },
    }
}));

export default function ProcurementVendors({
    vendorProfileIds, mainProfileId, curEntityId, entity, walletId
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [filteredContactsArr, setFilteredContactsArr] = useState([]);
    const [loader, setLoader] = useState(true)
    const [openRelationDetails, setOpenRelationDetails] = useState(false)
    const [financialRelation, setFinancialRelation] = useState(null)

    const getAllContactsApiCall = async () => {
        setLoader(true)
        await getProcurementVendors({
            mainProfileId,
            vendorProfileIds,
        })
            .then((data) => {
                console.log(data)
                setFilteredContactsArr(data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoader(false)
            })
    }

    useEffect(() => {
        if (mainProfileId && vendorProfileIds && vendorProfileIds.length > 0) {
            getAllContactsApiCall()
        }
    }, [mainProfileId, vendorProfileIds])

    return (
        <Paper className={classes.root}>
            <h4>Vendors</h4>
            <div className={classes.allCardsContStyle}>
                {loader ? (
                    <>
                        <Skeleton variant="rect" className={classes.cardSkeletonStyle} />
                        <Skeleton variant="rect" className={classes.cardSkeletonStyle} />
                        <Skeleton variant="rect" className={classes.cardSkeletonStyle} />
                        <Skeleton variant="rect" className={classes.cardSkeletonStyle} />
                    </>
                ) : (
                    <>
                        {filteredContactsArr && filteredContactsArr.length > 0 ? (
                            filteredContactsArr.map((contactData, i) => (
                                <Paper
                                    className={classes.singleCardStyle}
                                    key={i}
                                    elevation={0}
                                    onClick={() => {
                                        setFinancialRelation(contactData);
                                        setOpenRelationDetails(true);
                                    }}
                                >
                                    <Avatar
                                        style={{ width: "35px", height: "35px" }}
                                        src={contactData?.profile?.parent?.displayPicture?.url}
                                        alt={contactData?.profile?.parent?.displayName}
                                    />
                                    <div className={classes.infoCont}>
                                        <h3>
                                            <LessText
                                                limit={25}
                                                string={contactData?.profile?.parent?.displayName}
                                            />
                                        </h3>
                                        <p>
                                            {contactData?.profile?.parent?.username ? `@${contactData?.profile?.parent?.username}` : contactData?.profile?.parent?.email}
                                        </p>
                                    </div>
                                </Paper>
                            ))
                        ) : (
                            <div className={classes.emptyCont}>
                                <img src={emptyIcon} />
                                <p>No vendor is available.</p>
                            </div>
                        )}
                    </>
                )}
                <ContactAnalysis
                    openRelationDetails={openRelationDetails}
                    setOpenRelationDetails={setOpenRelationDetails}
                    financialRelation={financialRelation}
                    curEntityId={curEntityId}
                    entity={entity}
                    walletId={walletId}
                    curEntityProfileId={mainProfileId}
                />
            </div>
        </Paper>
    );
}